import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, DialogActions, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Iconify from '../../../components/Iconify';
import ModalComponent from '../../../components/ModalComponent';
import { ActionButton, ThemeButton } from '../../../components/button';
import StatusButtons from '../../../components/buttonGroup';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import { Types } from '../../../types';
import { filterEmptyValues } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { massEditRoles } from './rolesSlice';

const RadioNodeListToolbar = (props) => {
  const {
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
    statusUpdateUrl,
    rows,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, getPayload, fetchData } = toolbarProps;
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const [isModalopen, setisModalopen] = useState(false);

  const handleSelectionClick = async (selectedIds, status) => {
    setIsStatusModalOpen(false);
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (statusUpdateUrl && selectedIds && selectedIds?.length > 0) {
      const initialPayload = { areaIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: statusUpdateUrl,
          method: 'PATCH',
          data: getPayload ? getPayload(selected, status, id) : initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          if (fetchData) {
            fetchData();
          }
          // dispatch(getAreas({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const form = useForm({
    defaultValues: {
      position: null,
      // optional fields
      studyTypes: [],
    },
    // resolver: rowToEdit ? yupResolver(editRoleSchema) : yupResolver(addRoleSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control, clearErrors } = form;
  const { errors } = formState;
  const positionOptions = [
    { label: 'Area Manager', value: 1 },
    { label: 'Manager', value: 2 },
    { label: 'Supervisor/Team Leader', value: 3 },
    { label: 'Colleague', value: 4 },
  ];
  const closeModal = () => {
    clearErrors();
    setisModalopen((prevState) => !prevState);
    reset();
  };
  const onSubmit = (values) => {
    if (rows && rows?.length > 0 && selected && selected?.length > 0) {
      const payload = selected?.map((selectedId) => {
        const observation = rows?.find((row) => row?._id === selectedId) || null;
        return observation;
      });
      const finalPayload = payload?.map((observation) => {
        const obsObject = {
          studyTypes: values?.studyTypes?.length > 0 ? values?.studyTypes : observation?.projectSetting?.studyTypes,
          position: get(values, 'position') ? get(values, 'position') : observation?.projectSetting?.position,
          _id: observation?._id,
          customerID: observation?.projectSetting?.customerID,
          projectID: id,
        };
        const filteredPayload = filterEmptyValues(obsObject);
        return filteredPayload;
      });
      dispatch(massEditRoles(finalPayload))
        .then((response) => {
          if (response?.payload.successCount <= 0) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          }
          closeModal();
          handleClearSelection();
          fetchData();
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: 'Something went wrong',
            })
          );
        });
    }
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
  ];
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('active');
  const handleStatusButtonClick = (selectedIds, status) => {
    setCurrentStatus(() => status);
    setIsStatusModalOpen(() => true);
  };
  const handleDeleteElements = async () => {
    if (selected?.length > 0) {
      handleOpenBackdrop();
      setIsStatusModalOpen(false);
      try {
        const response = await axiosInstance({
          url: `roles/delete/${selected[0]}`,
          method: 'DELETE',
          data: { projectID: id, roleIDs: selected },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          handleClearSelection();
          fetchData();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <>
      <ConfirmDialog
        title={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
        open={isStatusModalOpen}
        setOpen={setIsStatusModalOpen}
        selectedField={selected}
        onConfirm={() => (currentStatus === 'delete' ? handleDeleteElements() : handleSelectionClick(selected, currentStatus))}
        confirmText={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
      >
        {currentStatus === 'delete' ? (
          <Typography variant="body1">
            Are you sure you want to <b>delete</b> this?
          </Typography>
        ) : (
          <Typography variant="body1">
            Are you sure you want to change the status to <b>{currentStatus}</b> ?
          </Typography>
        )}
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px',
          },
        }}
        title="Mass Edit Roles"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} type="submit" onClick={handleSubmit(onSubmit)} variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  options={studyAppOptions}
                  config={studyAppConfig}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                    '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                    '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                    '& .MuiFormLabel-root .Mui-error ': { color: '#212B36 !important', fontWeight: '600' },
                    width: '80%',
                  }}
                >
                  <GroupCheckbox
                    error={!!errors?.position}
                    helperText={errors?.position && errors?.position?.message}
                    label="Select Position"
                  >
                    <FormRadioGroup fontSize={18} options={positionOptions} control={control} name="position" />
                  </GroupCheckbox>
                </Box>
                {/* <GroupCheckbox label="User Permissions">
              <FormRadioGroup options={options} control={control} name="userPermission" />
            </GroupCheckbox> */}
              </Grid>
            </Grid>
          </Box>
        </form>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="primary"
              onClick={() => setisModalopen(true)}
            >
              {`Mass Edit ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
            {toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="delete"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'delete', handleClearSelection)
                }
              >
                {`Remove ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'active', handleClearSelection)
                }
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'archive', handleClearSelection)
                }
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={4} xl={3} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search customer name...'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={5} lg={5}>
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                <IconButton color="primary">
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <ThemeButton
                  onClick={toolbarProps?.openModal && toolbarProps?.openModal}
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="outlined"
                >
                  {addButtonName || 'Add Area'}
                </ThemeButton>
                {/* <Typography>Status</Typography> */}
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={handleStatusChange}
                  fullWidth
                  size="small"
                  // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                  sx={{ width: '60%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

RadioNodeListToolbar.propTypes = {
  selected: PropTypes.array,
  // handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  statusUpdateUrl: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  rows: PropTypes.array,
};

export default RadioNodeListToolbar;
