/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Box, Chip, Container, DialogActions, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { get, isEmpty, startCase } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Iconify from '../../../components/Iconify';
import ModalComponent from '../../../components/ModalComponent';
import { ThemeButton } from '../../../components/button';
import FormInput from '../../../components/formComponents/FormInput';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { addFieldUserSchema, editFieldUserSchema } from '../../../utils/schema';
import { addFieldUser, editFieldUser, getFieldUsers } from './fieldUserSlice';
import { getCustomers } from '../../customer/customerSlice';

const FieldUserListPage = () => {
  const headCells = [
    {
      id: 'fullName',
      label: 'Name',
      // disablePadding: true,
      align: 'left',
      // pl: 4,
      titleCase: true,
    },
    {
      id: 'customerName',
      label: 'Customer',
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => <StatusIndicator cell={cell} />,
    },
  ];
  const [rowToEdit, setRowToEdit] = useState('');
  const form = useForm({
    defaultValues: {
      name: '',
      lastname: '',
      email: '',
      password: '',
      canBeUsedByCustomers: [],
      customerID: '',
      // permission: '',
    },
    resolver: rowToEdit ? yupResolver(editFieldUserSchema) : yupResolver(addFieldUserSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;

  const [isModalopen, setisModalopen] = useState(false);
  const { fieldUsers, isLoading, isUserAdding } = useSelector((state) => state.fieldUser);

  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [Status, setStatus] = useState(ACTIVE);
  const [showPassword, setShowPassword] = useState(false);
  const [allStatusModelOpened, setAllStatusModelOpened] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [statusToChange, setStatusToChange] = useState('');
  const [userRows, setUserRows] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);
  const { customers } = useSelector((state) => state?.customer);
  const customer = watch('customerID');
  const isRetimeCustomer = useMemo(() => customer === '5d4adde9fd1a797f3bee16d6', [customer]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setUserRows(regularUsers.default);
      } else if (value === ACTIVE) {
        setUserRows(regularUsers.active);
      } else if (value === ARCHIVED) {
        setUserRows(regularUsers.archive);
      }
    }
  };
  const regularUsers = useMemo(
    () =>
      !isEmpty(fieldUsers) &&
      fieldUsers.reduce(
        (acc, user) => {
          const fullUser = {
            ...user,
            fullName: `${startCase(user?.name)} ${startCase(user?.lastname)}`,
            customerName: customers?.find((field) => field?._id === user?.customerID)?.name || '',
            // userPermission: get(user, 'permission') && getUserPermissionLabel(user?.permission),
          };
          if (user?.status === 'active') {
            acc.active.push(fullUser);
          } else if (user?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(fullUser);
          }
          acc.default.push(fullUser);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [customers, fieldUsers]
  );
  useEffect(() => {
    if (regularUsers) {
      if (Status === DEFAULT) {
        setUserRows(regularUsers?.default);
      } else if (Status === ARCHIVED) {
        setUserRows(regularUsers?.archive);
      } else if (Status === ACTIVE) {
        setUserRows(regularUsers?.active);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularUsers]);
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search user name',
  };
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    // setisModalopen(false);
    reset();
    reset({ canBeUsedByCustomers: [] });
    setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const openModal = () => setisModalopen(true);
  useEffect(() => {
    dispatch(getFieldUsers());
  }, [dispatch]);
  const customerOptions = useMemo(() => {
    if (customers && customers?.length >= 0) {
      return customers
        ?.filter((currentCustomer) => currentCustomer?.status === 'active')
        ?.map((field) => ({ label: field?.name, value: field?._id }));
    }
    return [];
  }, [customers]);
  const onSubmit = (data) => {
    const { canBeUsedByCustomers, ...rest } = data;
    const payload = { ...rest };
    if (isRetimeCustomer && !!canBeUsedByCustomers?.length) {
      payload.canBeUsedByCustomers = canBeUsedByCustomers;
    } else {
      payload.canBeUsedByCustomers = [];
    }
    if (payload) {
      // role: 1, status: Types.ACTIVE
      if (rowToEdit) {
        dispatch(editFieldUser({ ...payload, _id: rowToEdit })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            dispatch(getFieldUsers());
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      } else {
        dispatch(addFieldUser(payload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'User Added Successfully'),
                })
              );
              dispatch(getFieldUsers());
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const onEditClick = async (event, row) => {
    // setRowToEdit((row?._id));
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      openModal();
      try {
        const response = await axiosInstance({
          url: `users/get/${row?._id}`,
          method: 'GET',
        });
        if (get(response, 'data.data', '')) {
          setValue('name', get(response, 'data.data.name', ''));
          setValue('lastname', get(response, 'data.data.lastname', ''));
          setValue('email', get(response, 'data.data.email', ''));
          setValue('status', get(response, 'data.data.status', ''));
          setValue('customerID', get(response, 'data.data.customerID', ''));
          setValue('canBeUsedByCustomers', get(response, 'data.data.canBeUsedByCustomers', []));
          // setValue('permission', get(response, 'data.data.permission', ''));
        }
      } catch (error) {
        console.log('error -> getUsersById');
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'users/update/status',
          method: 'PATCH',
          data: { userIDs: [row?._id], status },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} User`,
            })
          );
          dispatch(getFieldUsers());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `users/delete/${row?._id}`,
          method: 'DELETE',
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getFieldUsers());
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const onConfirmStatusChange = () => {};
  const handleSelectionClick = (selected, type, setSelectedField) => {
    if (selected && !!selected?.length && type) {
      setSelectedUsers(selected);
      setStatusToChange(type);
      setAllStatusModelOpened(() => true);
    }
  };
  return (
    <Container maxWidth="xxl">
      <ConfirmDialog
        title="Change Status"
        open={allStatusModelOpened}
        setOpen={setAllStatusModelOpened}
        confirmText="Change Status"
        onConfirm={onConfirmStatusChange}
      >
        <Typography variant="body1">
          Are you sure you want to <b>{statusToChange === 'active' ? 'activate' : 'archive'}</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title={rowToEdit ? 'Edit User' : 'Create Field User'}
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={2} container>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="First Name"
                  placeholder="Enter First Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="Last Name"
                  placeholder="Enter Last Name"
                  InputProps={{ ...register('lastname') }}
                  error={!!errors?.lastname}
                  helperText={errors?.lastname && errors?.lastname?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label="Email Address"
                  placeholder="Enter Email Address"
                  InputProps={{ ...register('email'), autoComplete: 'off' }}
                  error={!!errors?.email}
                  helperText={errors?.email && errors?.email?.message}
                />
              </Grid>
              <Grid item lg={6} sm={12} xs={12} md={6}>
                <FormInput
                  label={rowToEdit ? 'Change Password' : 'Password'}
                  placeholder="Enter Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    ...register('password'),
                    // type: 'password',
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          edge="end"
                        >
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.password}
                  helperText={errors?.password && errors?.password?.message}
                />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <label style={{ fontSize: '0.8600rem' }}>Customer</label>
                <CustomSelect
                  name="customerID"
                  control={control}
                  options={customerOptions}
                  label="Select a Customer"
                  error={!!errors?.customerID}
                  helperText={errors?.customerID && errors?.customerID?.message}
                />
              </Grid>
              {isRetimeCustomer && (
                <Grid mt={2} item lg={12} sm={12} xs={12} md={12}>
                  <label style={{ fontSize: '0.8600rem' }}>Can be used by these customers as well: </label>
                  <CustomSelect
                    multiple
                    name="canBeUsedByCustomers"
                    displayEmpty
                    control={control}
                    options={customerOptions}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <em>All Customers</em>;
                      }
                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.length > 0 &&
                            selected.map((value) => (
                              <Chip key={value} label={customerOptions?.find((opt) => opt?.value === value)?.label} />
                            ))}
                        </Box>
                      );
                    }}
                    label="Select one or more Customer"
                    error={!!errors?.customerID}
                    helperText={errors?.customerID && errors?.customerID?.message}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={isUserAdding} type="submit" variant="contained">
              {rowToEdit ? 'Save Changes' : 'Create Field User'}
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
        <Typography variant="h4">Field Users</Typography>
        <ThemeButton onClick={openModal} variant="contained" color="success" startIcon={<PersonAddAltIcon />}>
          Create Field User
        </ThemeButton>
      </Box>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        onEditClick={onEditClick}
        onRowStatusChange={onRowStatusChange}
        defaultRowsPerPage={10}
        maxHeight={653}
        filterKeys={['fullName', 'customerName']}
        nestedRowKey={Types.nestedRowTypes.ADMIN_FIELD_USER}
        customToolbar={Types.toolbarTypes.SUPER_USER}
        toolbarProps={toolbarProps}
        handleSelectionClick={handleSelectionClick}
        rows={userRows}
        stickyHeader
        onRowDelete={onDelete}
      />
    </Container>
  );
};

export default FieldUserListPage;
