import { yupResolver } from '@hookform/resolvers/yup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Box, DialogActions, Grid } from '@mui/material';
import { get, has, isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalComponent from '../../../components/ModalComponent';
import StudiesComponent from '../../../components/StudiesComponent';
import { ThemeButton } from '../../../components/button';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import ThemeCheckbox from '../../../components/checkbox/themeChekbox';
import FormInput from '../../../components/formComponents/FormInput';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import StatusIndicator from '../../../components/status';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { addAreaSchema } from '../../../utils/schema';
import { addArea, getAreas, updateArea } from './areaSlice';
import { getCategories } from '../category/projectCategoriesSlice';

const AreasPage = ({ projectID, customerID }) => {
  const dispatch = useDispatch();
  const { areas, isLoading, addAreaLoading } = useSelector((state) => state.areas);
  const headCells = [
    {
      id: 'name',
      numeric: false,
      // disablePadding: true,
      label: 'Area',
      // titleCase: true,
      minWidth: '170px',
      // align: 'left',
    },
    {
      id: 'category',
      disablePadding: false,
      label: 'Category',
      minWidth: '170px',
    },
    {
      id: 'inCustomerCount',
      disablePadding: false,
      label: 'In Customer Count',
      align: 'left',
      // maxWidth: '140px',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.inCustomerCount') && get(cell, 'projectSetting.inCustomerCount') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 4 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 4 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'inTracking',
      disablePadding: false,
      label: 'In Tracking',
      align: 'left',
      // maxWidth: '100px',
      renderElement: (cell) =>
        !isUndefined(cell, 'projectSetting.inTracking') && get(cell, 'projectSetting.inTracking') ? (
          <CheckCircleOutlineOutlinedIcon sx={{ ml: 2 }} fontSize="small" color="success" />
        ) : (
          <CancelOutlinedIcon sx={{ ml: 2 }} fontSize="small" color="error" />
        ),
    },
    {
      id: 'studiesLength',
      disablePadding: false,
      minWidth: '360px',
      maxWidth: '360px',
      label: 'Studies',
      align: 'center',
      renderElement: (cell) => <StudiesComponent studyTypes={get(cell, 'projectSetting.studyTypes')} />,
    },
    {
      id: 'status',
      label: 'Status',
      renderElement: (cell) => has(cell, 'projectSetting.status') && <StatusIndicator cell={cell?.projectSetting} />,
      width: '15%',
    },
  ];
  useEffect(() => {
    dispatch(getAreas({ projectID }));
  }, [dispatch, projectID]);
  const { projectCategories: categories } = useSelector((state) => state.projectCategories);
  const fetchData = async () => {
    dispatch(getAreas({ projectID }));
  };
  useEffect(() => {
    if (projectID) {
      dispatch(getCategories({ defaultEntry: false, projectID }));
    }
  }, [dispatch, projectID]);
  const regularAreas = useMemo(
    () =>
      !isEmpty(areas) &&
      !!areas?.length &&
      areas.reduce(
        (acc, area) => {
          const areaObject = {
            ...area,
            status: get(area, 'projectSetting.status'),
            category:
              categories?.length > 0
                ? categories?.find((category) => category?._id === area?.projectSetting?.defaultCategory)?.name ||
                  'No default category'
                : 'No default category',
            inTracking: get(area, 'projectSetting.inTracking'),
            inCustomerCount: get(area, 'projectSetting.inCustomerCount'),
            studiesLength: get(area, 'projectSetting.studyTypes') && area?.projectSetting?.studyTypes?.length,
          };
          if (area?.projectSetting?.status === 'active') {
            acc.active.push(areaObject);
          } else if (area?.projectSetting?.status === 'archive') {
            // acc.archieved.push(customer);
            acc.archive.push(areaObject);
          }
          acc.default.push(areaObject);
          return acc;
        },
        { active: [], archive: [], default: [] }
      ),
    [areas, categories]
  );
  const [areaRows, setAreaRows] = useState([]);
  const { DEFAULT, ACTIVE, ARCHIVED } = Types;
  const [isModalopen, setisModalopen] = useState(false);
  const [Status, setStatus] = useState(ACTIVE);
  const [rowToEdit, setRowToEdit] = useState('');
  useEffect(() => {
    if (regularAreas) {
      if (Status === DEFAULT) {
        setAreaRows(regularAreas?.default);
      } else if (Status === ARCHIVED) {
        setAreaRows(regularAreas?.archive);
      } else if (Status === ACTIVE) {
        setAreaRows(regularAreas?.active);
      }
    } else {
      setAreaRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularAreas]);
  const onStatusChange = (value) => {
    if (value) {
      setStatus(value);
      if (value === DEFAULT) {
        setAreaRows(regularAreas.default);
      } else if (value === ACTIVE) {
        setAreaRows(regularAreas.active);
      } else if (value === ARCHIVED) {
        setAreaRows(regularAreas.archive);
      }
    }
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: `areas/delete/${row?._id}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getAreas({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const studyAppConfig = { label: 'label', value: 'value' };
  const studyAppOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
    // { label: 'Predetermined Study', value: 4, color: '#9CC13B' },
  ];
  const form = useForm({
    defaultValues: {
      name: '',
      projectID: '',
      customerID: '',
      defaultCategory: '',
      inTracking: false,
      inCustomerCount: false,
      studyTypes: [],
    },
    resolver: yupResolver(addAreaSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const categoryOptions = useMemo(
    () =>
      categories?.length >= 0 &&
      categories
        .filter((category) => category?.projectSetting?.status === 'active')
        .map((category) => ({ label: category?.name, value: category?._id })),
    [categories]
  );
  const openModal = () => setisModalopen(true);
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };

  const onEditClick = async (event, row) => {
    if (row?._id) {
      setRowToEdit((prevRow) => row?._id);
      const areaToEdit = areas.find((area) => area?._id === row?._id);
      setValue('name', get(areaToEdit, 'name', ''));
      setValue('defaultCategory', get(areaToEdit, 'projectSetting.defaultCategory', ''));
      setValue('inTracking', get(areaToEdit, 'projectSetting.inTracking', ''));
      setValue('inCustomerCount', get(areaToEdit, 'projectSetting.inCustomerCount', []));
      setValue('studyTypes', get(areaToEdit, 'projectSetting.studyTypes', []));
      openModal();
    } else {
      console.warn('No Row Id found');
    }
  };
  const onRowStatusChange = async (row, status, startLoading, closeLoading) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (row?._id) {
      startLoading();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'areas/update/status',
          method: 'PATCH',
          data: { areaIDs: [row?._id], status, projectID },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          dispatch(getAreas({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const handleSelectionClick = (selectedIds, operation, handleClearSelection) => {};
  const onSubmit = (values) => {
    const { defaultCategory, ...rest } = values;
    const payload = { ...rest };
    if (defaultCategory && defaultCategory !== '') {
      payload.defaultCategory = defaultCategory;
    }
    if (customerID && projectID) {
      if (rowToEdit) {
        dispatch(updateArea({ ...payload, customerID, projectID, _id: rowToEdit }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Area Updated Successfully'),
                })
              );
              dispatch(getAreas({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      } else {
        dispatch(addArea({ ...payload, customerID, projectID }))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Area Added Successfully'),
                })
              );
              dispatch(getAreas({ projectID }));
              closeModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const getPayload = (selected, status, id) => ({ areaIDs: selected, status, projectID: id });
  const toolbarProps = {
    status: Status,
    onStatusChange,
    placeholder: 'Search Areas',
    openModal,
    handleSelectionClick,
    getPayload,
    fetchData,
  };
  return (
    <Box>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '600px !important',
            maxHeight: '700px',
          },
        }}
        title={rowToEdit ? 'Edit Area' : 'Create Area'}
        // title="Add Area"
        open={isModalopen}
        onClose={closeModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1}>
            <Grid columnSpacing={3} rowSpacing={2} container justifyContent="space-between">
              {/* {!rowToEdit && ( */}
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <FormInput
                  label="Name"
                  placeholder="Enter Name"
                  InputProps={{
                    ...register('name'),
                  }}
                  disabled={rowToEdit}
                  error={!!errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              </Grid>
              {/* )} */}
              <Grid item lg={12} sm={12} xs={12} md={12}>
                {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
                <label style={{ fontSize: '0.8600rem' }}>Category</label>
                <CustomSelect
                  name="defaultCategory"
                  control={control}
                  options={[{ label: 'No Category', value: '' }, ...categoryOptions]}
                  label="Category"
                  error={!!errors?.defaultCategory}
                  helperText={errors?.defaultCategory && errors?.defaultCategory?.message}
                />
              </Grid>
              <Grid display="flex" flexDirection="column" item lg={12} sm={12} xs={12} md={12}>
                <ThemeCheckbox name="inCustomerCount" control={control} label="Appear in Customer Count" />
                <ThemeCheckbox name="inTracking" control={control} label="Appear in Tracking" />
              </Grid>
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <CheckboxGroup
                  control={control}
                  label="Study Types"
                  name="studyTypes"
                  options={studyAppOptions}
                  config={studyAppConfig}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addAreaLoading} type="submit" variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Save Changes
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={areaRows}
        searchLable="Search Areas"
        onRowStatusChange={onRowStatusChange}
        onEditClick={onEditClick}
        handleSelectionClick={handleSelectionClick}
        filterKeys={['name', 'category']}
        defaultRowsPerPage={10}
        customToolbar={Types.toolbarTypes.AREAS}
        maxHeight={453}
        toolbarProps={toolbarProps}
        hideCompactViewButton
        statusUpdateUrl="areas/update/status"
        onRowDelete={onDelete}
      />
    </Box>
  );
};

export default AreasPage;
AreasPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
