/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Chip, DialogActions, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { ThemeButton } from '../components/button';
import FormInput from '../components/formComponents/FormInput';
import Iconify from '../components/Iconify';
import { setSnackbar } from '../components/snackbar/snackbarSlice';
import { editAdminUserSchema, editSuperUserSchema } from '../utils/schema';
import { editSuperUser } from './user/super/superUserSlice';
import { getCustomers } from './customer/customerSlice';
import { editAdminUser } from './user/admin/adminUserSlice';
import CustomSelect from '../components/select';
import GroupCheckbox from '../components/formComponents/FormCheckbox';
import FormRadioGroup from '../components/formComponents/formRadioGroup';
import { fetchCurrentUser } from './login/loginSlice';

const ProfilePage = (props) => {
  const { userName, role, userDetails } = useSelector((state) => state.login);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const decoded = jwtToken && jwtDecode(jwtToken);

  const superUserDefaultValues = {
    name: '',
    lastname: '',
    email: '',
    password: '',
  };
  const adminUserDefaultValues = {
    name: '',
    lastname: '',
    email: '',
    password: '',
    internalEmployee: false,
    canBeUsedByCustomers: [],
    customerID: '',
    permission: '',
  };
  const getDefaultValues = () => {
    if (role === 2) {
      return adminUserDefaultValues;
    }
    if (role === 3) {
      return null;
    }
    return superUserDefaultValues;
  };
  const getSchema = () => {
    if (role === 2) {
      return editAdminUserSchema;
    }
    if (role === 3) {
      return null;
    }
    return editSuperUserSchema;
  };
  const form = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(getSchema()),
  });
  const { register, handleSubmit, formState, watch, control, reset, getValues, setValue } = form;
  const { errors } = formState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customers } = useSelector((state) => state.customer);
  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);
  useEffect(() => {
    if (decoded?._id) {
      dispatch(
        fetchCurrentUser({
          id: decoded?._id,
        })
      );
    }
  }, [decoded?._id, dispatch]);

  useEffect(() => {
    if (userDetails && role) {
      if (role === 1) {
        setValue('name', get(userDetails, 'name'));
        setValue('lastname', get(userDetails, 'lastname'));
        setValue('email', get(userDetails, 'email'));
      } else if (role === 2) {
        setValue('name', get(userDetails, 'name'));
        setValue('lastname', get(userDetails, 'lastname'));
        setValue('email', get(userDetails, 'email'));
        setValue('status', get(userDetails, 'status'));
        setValue('customerID', get(userDetails, 'customerID'));
        setValue('permission', get(userDetails, 'permission'));
        setValue('canBeUsedByCustomers', get(userDetails, 'canBeUsedByCustomers'));
        setValue('internalEmployee', get(userDetails, 'internalEmployee'));
      }
    }
  }, [role, setValue, userDetails]);
  const customerOptions = useMemo(() => {
    if (customers && customers?.length >= 0) {
      return customers?.map((customer) => ({ label: customer?.name, value: customer?._id }));
    }
    return [];
  }, [customers]);
  const [showPassword, setShowPassword] = useState(false);
  const closeModal = () => {
    handleBack();
    reset(getDefaultValues());
    setShowPassword(false);
  };

  const options = [
    { label: 'Project Manager', value: 1 },
    { label: 'View Only/Field User', value: 2 },
    // { label: 'View Only', value: 3 },
  ];
  const internalEmployeeOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  const customer = watch('customerID');
  const isRetimeCustomer = useMemo(() => customer === '5d4adde9fd1a797f3bee16d6', [customer]);
  useEffect(() => {
    if (isRetimeCustomer) {
      setValue('internalEmployee', false);
    } else {
      setValue('canBeUsedByCustomers', []);
    }
  }, [isRetimeCustomer, setValue]);

  const onSubmit = (values) => {
    if (role === 1) {
      dispatch(editSuperUser({ ...values, role: 1, _id: userDetails?._id })).then((response) => {
        if (response.payload?.success) {
          closeModal();
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
            })
          );
          // navigate
          navigate(-1);
        } else {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      });
    } else if (role === 2) {
      const { canBeUsedByCustomers, internalEmployee, ...rest } = values;
      const payload = { ...rest };
      if (isRetimeCustomer && !!canBeUsedByCustomers?.length) {
        payload.canBeUsedByCustomers = canBeUsedByCustomers;
        payload.internalEmployee = false;
      } else {
        payload.canBeUsedByCustomers = [];
        payload.internalEmployee = internalEmployee;
      }
      if (payload) {
        dispatch(editAdminUser({ ...payload, role: 2, _id: userDetails?._id })).then((response) => {
          if (response.payload?.success) {
            closeModal();
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message' || 'User Updated Successfully'),
              })
            );
            navigate(-1);
          } else {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        });
      }
    }
  };
  const handleBack = () => {
    navigate(-1); // This will take you back to the previous page
  };
  const renderRoleWIseFOrm = () => {
    if (role === 2) {
      return (
        <Box m={3}>
          <Typography sx={{ mb: 1 }} variant="h4">
            Profile
          </Typography>
          <Grid columnSpacing={2} container>
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <FormInput
                label="First Name"
                placeholder="Enter First Name"
                InputProps={{
                  ...register('name'),
                }}
                error={!!errors?.name}
                helperText={errors?.name && errors?.name?.message}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <FormInput
                label="Last Name"
                placeholder="Enter Last Name"
                InputProps={{ ...register('lastname') }}
                error={!!errors?.lastname}
                helperText={errors?.lastname && errors?.lastname?.message}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <FormInput
                label="Email Address"
                placeholder="Enter Email Address"
                InputProps={{ ...register('email'), autoComplete: 'off' }}
                error={!!errors?.email}
                helperText={errors?.email && errors?.email?.message}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={6}>
              <FormInput
                label="Change Password"
                placeholder="Enter Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  ...register('password'),
                  // type: 'password',
                  autoComplete: 'off',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.password}
                helperText={errors?.password && errors?.password?.message}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <label style={{ fontSize: '0.8600rem' }}>Customer</label>
              <CustomSelect
                name="customerID"
                control={control}
                options={customerOptions}
                label="Select a Customer"
                error={!!errors?.customerID}
                helperText={errors?.customerID && errors?.customerID?.message}
              />
            </Grid>
            {!isRetimeCustomer ? (
              <Grid mt={2} item lg={12} sm={12} xs={12} md={12}>
                <Box
                  sx={{
                    '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                    '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                    '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                    '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                    mt: 1,
                  }}
                >
                  <GroupCheckbox
                    error={!!errors?.internalEmployee}
                    helperText={errors?.internalEmployee && errors?.internalEmployee?.message}
                    label="ReThink Productivity Employee ?"
                  >
                    <FormRadioGroup
                      row
                      fontSize={18}
                      options={internalEmployeeOptions}
                      control={control}
                      name="internalEmployee"
                    />
                  </GroupCheckbox>
                </Box>
              </Grid>
            ) : (
              <Grid mt={2} item lg={12} sm={12} xs={12} md={12}>
                <label style={{ fontSize: '0.8600rem' }}>Can be used by these customers as well: </label>
                <CustomSelect
                  multiple
                  name="canBeUsedByCustomers"
                  displayEmpty
                  control={control}
                  options={customerOptions}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>All Customers</em>;
                    }
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected?.length > 0 &&
                          selected.map((value) => (
                            <Chip key={value} label={customerOptions?.find((opt) => opt?.value === value)?.label} />
                          ))}
                      </Box>
                    );
                  }}
                  label="Select a Customer"
                  error={!!errors?.customerID}
                  helperText={errors?.customerID && errors?.customerID?.message}
                />
              </Grid>
            )}
            <Grid
              sx={{
                '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                '& .css-14r9679-MuiFormLabel-root': { color: '#212B36' },
              }}
              mt={2}
              item
              lg={12}
              sm={12}
              xs={12}
              md={12}
            >
              <GroupCheckbox
                error={!!errors?.permission}
                helperText={errors?.permission && errors?.permission?.message}
                label="Select User Permissions"
              >
                <FormRadioGroup fontSize={18} options={options} control={control} name="permission" />
              </GroupCheckbox>
            </Grid>
          </Grid>
        </Box>
      );
    }
    return (
      <Box m={3}>
        <Typography sx={{ mb: 1 }} variant="h4">
          Profile
        </Typography>
        <FormInput
          label="First Name"
          placeholder="Enter First Name"
          InputProps={{
            ...register('name'),
          }}
          error={!!errors?.name}
          helperText={errors?.name && errors?.name?.message}
        />
        <FormInput
          label="Last Name"
          placeholder="Enter Last Name"
          InputProps={{ ...register('lastname') }}
          error={!!errors?.lastname}
          helperText={errors?.lastname && errors?.lastname?.message}
        />
        <FormInput
          label="Email Address"
          placeholder="Enter Email Address"
          InputProps={{ ...register('email'), autoComplete: 'off' }}
          error={!!errors?.email}
          helperText={errors?.email && errors?.email?.message}
        />
        <FormInput
          label="Change Password"
          placeholder="Enter Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            ...register('password'),
            // type: 'password',
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end"
                >
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors?.password}
          helperText={errors?.password && errors?.password?.message}
        />
      </Box>
    );
  };
  return (
    <Grid sx={{ height: '90vh' }} display="flex" alignItems="center" justifyContent="center" container>
      <Grid item sm={12} md={3.4}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderRoleWIseFOrm()}
            <DialogActions sx={{ m: 3, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
                Go Back
              </ThemeButton>
              <ThemeButton loading={false} type="submit" variant="contained">
                Save Changes
              </ThemeButton>
            </DialogActions>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

ProfilePage.propTypes = {};

export default ProfilePage;
