/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { alpha, Box, Chip, DialogActions, Grid, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeButton } from '../../../components/button';
import FormInput from '../../../components/formComponents/FormInput';
import ModalComponent from '../../../components/ModalComponent';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import { getStudyColor } from '../../../components/StudiesComponent';
import ThemeSwitch from '../../../components/switch';
import DataTable from '../../../components/table';
import { Types } from '../../../types';
import axiosInstance from '../../../utils/axiosInstance';
import { addToObservationSchema } from '../../../utils/schema';
import FilterModal from '../../admin/element/FilterModal';
import { getTasks } from '../../tasksPage/taskSlice';
import { getAreas } from '../areas/areaSlice';
import { getElements } from '../elements/elementsSlice';
import { getRoles } from '../roles/rolesSlice';
import { getDataSummaries, getObservationCount } from './dataSummarySlice';
import { filterEmptyValues } from '../../../utils';
import { addToObservation } from '../../studiesPage/studiesSlice';
import EmptyIcon from '../../../assets/images/chart1.png';

const defaultDsPageColumnSettings = {
  rasStudy: {
    // 'Study Name': true,
    startTime: false,
    roleName: false,
    areaName: false,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  dotsStudy: {
    observationStartTime: true,
    areaName: true,
    taskName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
  diloStudy: {
    observationStartTime: true,
    areaName: true,
    taskName: true,
    roleName: true,
    elementName: true,
    categoryName: true,
    rating: true,
    bms: true,
    frequency: true,
    bmsPerUom: true,
    notes: true,
    image: true,
  },
};
const DataSummaryPage = ({ projectID, singleProjectData }) => {
  const [currentStudyType, setCurrentStudyType] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectID && currentStudyType) {
      dispatch(getDataSummaries({ projectID, studyType: currentStudyType }));
      dispatch(getObservationCount({ projectID, studyType: currentStudyType }));
    }
  }, [currentStudyType, dispatch, projectID]);
  const [rolesOptions, setRolesOptions] = useState([]);

  const fetchDataSummaries = () => {
    if (projectID && currentStudyType) {
      dispatch(getDataSummaries({ projectID, studyType: currentStudyType }));
      dispatch(getObservationCount({ projectID, studyType: currentStudyType }));
    }
  };
  const { dataSummaries, isLoading, obsLoading, obsCount } = useSelector((state) => state.dataSummaries);
  const { singleStudyData, addObservationLoading } = useSelector((state) => state.studies);
  const { tasks } = useSelector((state) => state.tasks);
  const { areas } = useSelector((state) => state.areas);
  const { elements } = useSelector((state) => state.projectElements);
  const { roles } = useSelector((state) => state?.roles);
  const [isColumnsModalOpened, setColumnsModal] = useState(false);
  const [filteredColSettings, setFilteredColSettings] = useState(defaultDsPageColumnSettings);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [filterBy, setFilterBy] = useState('');
  const [isObsModalOpen, setIsObsModalOpen] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [elementRating, setElementRating] = useState(false);
  const [elementCount, setElementCount] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    filterBy: '',
    filterColumns: [],
    sortOrder: '',
    sortBy: '',
    isLabelNumeric: false,
  });
  const defaultRolesOptions = useMemo(() => {
    if (roles && !!roles?.length) {
      return roles.map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles]);
  useEffect(() => {
    if (roles && !!roles?.length) {
      const optionsRoles = roles.map((role) => ({ label: role?.name, value: role?._id }));
      setRolesOptions(() => optionsRoles);
    } else {
      setRolesOptions(() => []);
    }
  }, [roles]);
  useEffect(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (
      !dsPageColumnSettings ||
      !dsPageColumnSettings?.rasStudy ||
      !dsPageColumnSettings?.dotsStudy ||
      !dsPageColumnSettings?.diloStudy
    ) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
    } else {
      setFilteredColSettings(dsPageColumnSettings);
    }
  }, []);
  useEffect(() => {
    if (projectID) {
      dispatch(getRoles({ projectID }));
      dispatch(getAreas({ projectID }));
      dispatch(getElements({ projectID, defaultEntry: false }));
      dispatch(getTasks({ projectID }));
    }
  }, [dispatch, projectID]);
  const form = useForm({
    defaultValues: {
      studyID: '',
      roundID: '',
      addAtObservationID: '',
      projectID,
      areaID: '',
      elementID: '',
      roleID: '',
      rating: '',
      frequency: null,
      notes: '',
    },
    resolver: yupResolver(addToObservationSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const elementID = watch('elementID');
  useEffect(() => {
    if (elementID) {
      const element = elements.find((curElement) => curElement?._id === elementID);
      if (element && element?.projectSetting?.rating) {
        setElementRating(element?.projectSetting?.rating);
      } else {
        setElementRating(null);
      }
      if (get(element, 'projectSetting.count') !== undefined && get(element, 'projectSetting.count') === false) {
        setValue('frequency', 1);
      }
      setElementCount(get(element, 'projectSetting.count', true));
    } else {
      setElementRating(null);
      setElementCount(true);
    }
  }, [elementID, elements, setValue]);
  const summaryRows = useMemo(() => {
    if (currentStudyType && dataSummaries && dataSummaries?.length > 0) {
      return dataSummaries?.map((summary) => {
        let formattedTime = '';
        let observationStartTime = '';
        if (summary?.roundStartTime) {
          const dateTime = dayjs(summary?.roundStartTime).utc(); // Ensure it's interpreted as UTC
          formattedTime = dateTime.format('HH:mm');
        }
        if (summary?.startTime) {
          const dateTime = dayjs(summary?.startTime).utc(); // Ensure it's interpreted as UTC
          // Format the time to hours and minutes
          const formattedStartTime = dateTime.format('HH:mm');
          // observationStartTime = formattedTime;
          observationStartTime = formattedStartTime;
        }
        return {
          ...summary,
          startTime: formattedTime,
          bms: get(summary, 'bms')?.toString(),
          frequency: get(summary, 'frequency')?.toString(),
          bmsPerUom: get(summary, 'bmsPerUom')?.toString(),
          roleName: get(summary, 'roleName'),
          _id: summary?.observationID,
          studyType: currentStudyType,
          roundID: summary?.roundID,
          isEc: get(summary, 'originalValues') && Object.keys(get(summary, 'originalValues')).length > 0,
          observationStartTime,
        };
      });
    }
    return [];
  }, [currentStudyType, dataSummaries]);
  const taskSelectOptions = useMemo(() => {
    if (tasks && !!tasks?.length) {
      return tasks.map((task) => ({ label: task?.name, value: task?._id }));
    }
    return [];
  }, [tasks]);
  const obsRatingOptions = useMemo(() => {
    const array = [];
    for (let i = 40; i <= 135; i += 5) {
      array.push({ label: i, value: i.toString() });
    }
    array.push({ label: 'Not Rated', value: 'Not Rated' });
    return array;
  }, []);
  const rolesSelectOptions = useMemo(() => {
    if (roles && !!roles?.length) {
      return roles.map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [roles]);
  const areasOptions = useMemo(() => {
    if (areas && !!areas?.length) {
      return areas.map((role) => ({ label: role?.name, value: role?._id }));
    }
    return [];
  }, [areas]);
  const elementSelectOptions = useMemo(() => {
    if (elements && !!elements?.length) {
      return elements.map((element) => ({ label: element?.name, value: element?._id }));
    }
    return [];
  }, [elements]);
  const startTimeOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueStartTime = [...new Set(summaryRows.map((studyRow) => studyRow.startTime))];
      return uniqueStartTime.map((startTime) => ({ label: startTime, value: startTime }));
    }
    return [];
  }, [summaryRows]);
  const roleOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueRoleNames = [...new Set(summaryRows.map((studyRow) => studyRow.roleName))];
      return uniqueRoleNames.map((roleName) => ({ label: roleName, value: roleName }));
    }
    return [];
  }, [summaryRows]);
  const areaOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueAreaNames = [...new Set(summaryRows.map((studyRow) => studyRow.areaName))];
      return uniqueAreaNames.map((areaName) => ({ label: areaName, value: areaName }));
    }
    return [];
  }, [summaryRows]);
  const elementOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueElements = [...new Set(summaryRows.map((studyRow) => studyRow.elementName))];
      return uniqueElements.map((elementName) => ({ label: elementName, value: elementName }));
    }
    return [];
  }, [summaryRows]);
  const categoryOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const ctegoryElements = [...new Set(summaryRows.map((studyRow) => studyRow.categoryName))];
      return ctegoryElements.map((categoryName) => ({ label: categoryName, value: categoryName }));
    }
    return [];
  }, [summaryRows]);
  const ratingOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const ratingElements = [...new Set(summaryRows.map((studyRow) => studyRow.rating))];
      return ratingElements.map((rating) => ({ label: rating, value: rating }));
    }
    return [];
  }, [summaryRows]);
  const bmsOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const bmsElements = [...new Set(summaryRows.map((studyRow) => studyRow.bms))];
      return bmsElements.map((bms) => ({ label: bms, value: bms }));
    }
    return [];
  }, [summaryRows]);
  const bmsPerUomOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const bmsElements = [...new Set(summaryRows.map((studyRow) => studyRow.bmsPerUom))];
      return bmsElements.map((bmsPerUom) => ({ label: bmsPerUom, value: bmsPerUom }));
    }
    return [];
  }, [summaryRows]);
  const frequencyOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const frequencyElements = [...new Set(summaryRows.map((studyRow) => studyRow.frequency))];
      return frequencyElements.map((frequency) => ({ label: frequency, value: frequency }));
    }
    return [];
  }, [summaryRows]);
  const studynameOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const studyElements = [...new Set(summaryRows.map((studyRow) => studyRow.studyName))];
      return studyElements.map((studyName) => ({ label: studyName, value: studyName }));
    }
    return [];
  }, [summaryRows]);
  const taskOptions = useMemo(() => {
    if (summaryRows && summaryRows?.length) {
      const uniqueTaskNames = [...new Set(summaryRows.map((studyRow) => studyRow.taskName))];
      return uniqueTaskNames.map((taskName) => ({ label: taskName, value: taskName }));
    }
    return [];
  }, [summaryRows]);
  const openFilter = (key, orderBy, isLabelNumeric) => {
    setIsFilterOpen(true);
    setFilterBy(key);
    if (orderBy) {
      setSortBy(orderBy);
    }
    if (isLabelNumeric) {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: true }));
    } else {
      setFilterConfig((prevConfig) => ({ ...prevConfig, isLabelNumeric: false }));
    }
  };
  const onEditClick = (event, row, nestedCollapsedRows, setNestedCollapsedRows) => {
    if (nestedCollapsedRows.indexOf(row?._id) !== -1) {
      setNestedCollapsedRows(nestedCollapsedRows.filter((collapsedRow) => collapsedRow !== row?._id));
    } else {
      setNestedCollapsedRows((prevRows) => [row?._id]);
    }
  };
  const closeFilter = () => setIsFilterOpen(false);
  const getStudyName = (studyType) => {
    if (!studyType) {
      return '';
    }
    if (studyType == 1) {
      return 'Efficiency';
    }
    if (studyType == 2) {
      return 'Role';
    }
    if (studyType == 3) {
      return 'Activity';
    }
    return '';
  };
  const headCells = useMemo(
    () => [
      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        onClick: () => openFilter('studyName', 'studyName'),
        minWidth: 130,
      },
      {
        id: 'isEc',
        // maxWidth: 36,
        align: 'left',
        renderElement: (cell) =>
          cell?.isEc ? (
            <Typography
              sx={{
                backgroundColor: 'primary.main',
                color: '#fff',
                ml: '15px',
                padding: '3px 6px',
                borderRadius: '21px',
                width: '27px',
              }}
              fontSize="small"
            >
              EC
            </Typography>
          ) : null,
      },
      {
        id: 'observationStartTime',
        numeric: false,
        label: 'Time',
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        // minWidth: 170,
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'taskName',
        disablePadding: false,
        label: 'Task',
        onClick: () => openFilter('taskName', 'taskName'),
        // renderElement: (cell) => <StudiesComponent studyTypes={cell?.studyTypes} />,
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 190,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        // minWidth: 180,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        // minWidth: 230,
      },
      {
        id: '',
        numeric: false,
        label: 'Images',
      },
    ],
    []
  );
  const rasHeadCells = useMemo(
    () => [
      {
        id: 'studyName',
        numeric: false,
        label: 'Study Name',
        onClick: () => openFilter('studyName', 'studyName'),
        minWidth: 140,
      },
      {
        id: 'startTime',
        numeric: false,
        label: 'Rnd',
        onClick: () => openFilter('startTime', 'startTime', true),
      },
      {
        id: 'roleName',
        numeric: false,
        label: 'Role',
        onClick: () => openFilter('roleName', 'roleName'),
      },
      {
        id: 'areaName',
        numeric: false,
        label: 'Area',
        // minWidth: 170,
        onClick: () => openFilter('areaName', 'areaName'),
      },
      {
        id: 'elementName',
        numeric: false,
        label: 'Element Name',
        // minWidth: 190,
        onClick: () => openFilter('elementName', 'elementName'),
      },
      {
        id: 'categoryName',
        numeric: false,
        label: 'Category',
        // minWidth: 180,
        onClick: () => openFilter('categoryName', 'categoryName'),
      },
      {
        id: 'rating',
        numeric: false,
        label: 'Rating',
        onClick: () => openFilter('rating', 'rating', true),
      },
      {
        id: 'bms',
        numeric: false,
        label: 'BMS',
        onClick: () => openFilter('bms', 'bms', true),
        renderElement: (cell) =>
          cell?.bms && (
            <Stack alignItems="center" justifyContent="center" sx={{ pr: 2 }}>
              <Chip size="small" sx={{ minWidth: 70, bgcolor: cell?.bmsFormatting || '' }} label={cell?.bms} />
            </Stack>
          ),
        align: 'center',
        minWidth: 150,
      },
      {
        id: 'frequency',
        numeric: false,
        label: 'Freq',
        onClick: () => openFilter('frequency', 'frequency', true),
      },
      {
        id: 'bmsPerUom',
        numeric: false,
        label: 'BMS/UOM',
        onClick: () => openFilter('bmsPerUom', 'bmsPerUom', true),
      },
      {
        id: 'notes',
        numeric: false,
        label: 'Notes',
        // minWidth: 250,
      },
      {
        id: '',
        numeric: false,
        label: 'Images',
      },
    ],
    []
  );

  useEffect(() => {
    switch (filterBy) {
      case 'startTime':
        setFilterOptions(startTimeOptions);
        break;
      case 'roleName':
        setFilterOptions(roleOptions);
        break;
      case 'areaName':
        setFilterOptions(areaOptions);
        break;
      case 'elementName':
        setFilterOptions(elementOptions);
        break;
      case 'categoryName':
        setFilterOptions(categoryOptions);
        break;
      case 'rating':
        setFilterOptions(ratingOptions);
        break;
      case 'bms':
        setFilterOptions(bmsOptions);
        break;
      case 'bmsPerUom':
        setFilterOptions(bmsPerUomOptions);
        break;
      case 'frequency':
        setFilterOptions(frequencyOptions);
        break;
      case 'studyName':
        setFilterOptions(studynameOptions);
        break;
      case 'taskName':
        setFilterOptions(taskOptions);
        break;
      default:
        setFilterOptions([]);
        break;
    }
  }, [
    areaOptions,
    bmsOptions,
    bmsPerUomOptions,
    categoryOptions,
    elementOptions,
    filterBy,
    frequencyOptions,
    ratingOptions,
    roleOptions,
    startTimeOptions,
    studynameOptions,
    taskOptions,
    taskSelectOptions,
  ]);

  const onSubmit = (values) => {
    const filteredPayload = filterEmptyValues(values);
    dispatch(addToObservation(filteredPayload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Observation Added Successfully'),
            })
          );
          fetchDataSummaries();
          closeObsModal();
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const filteredRasHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return rasHeadCells;
    }
    if (filteredColSettings && filteredColSettings?.rasStudy) {
      return rasHeadCells.filter((cell) => filteredColSettings?.rasStudy?.[cell?.id]);
    }
    return rasHeadCells;
  }, [filteredColSettings, rasHeadCells]);
  const filteredDotsHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return headCells;
    }
    if (filteredColSettings && filteredColSettings?.dotsStudy) {
      return headCells.filter((cell) => cell?._id === 'isEc' || filteredColSettings?.dotsStudy?.[cell?.id]);
    }
    return headCells;
  }, [filteredColSettings, headCells]);
  const filteredDilosHeadCells = useMemo(() => {
    const dsPageColumnSettings = JSON.parse(localStorage.getItem('dsPageColumnSettings'));
    if (!dsPageColumnSettings) {
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(defaultDsPageColumnSettings));
      return headCells;
    }
    if (filteredColSettings && filteredColSettings?.diloStudy) {
      return headCells.filter((cell) => cell?._id === 'isEc' || filteredColSettings?.diloStudy?.[cell?.id]);
    }
    return headCells;
  }, [filteredColSettings, headCells]);
  const finalHeadCells = useMemo(() => {
    if (currentStudyType === 1) {
      return filteredRasHeadCells;
    }
    if (currentStudyType === 2) {
      return filteredDilosHeadCells;
    }
    if (currentStudyType === 3) {
      return filteredDotsHeadCells;
    }
    return [];
  }, [currentStudyType, filteredDilosHeadCells, filteredDotsHeadCells, filteredRasHeadCells]);
  const nestedRowProps = {
    rolesSelectOptions,
    areasOptions,
    elementSelectOptions,
    taskSelectOptions,
    fetchDataSummaries,
  };
  const { studies } = useSelector((state) => state.studies);
  const toolbarProps = {
    setFilterConfig,
  };
  const onDelete = async (row, startLoading, closeLoading) => {
    if (row?._id && projectID) {
      startLoading();
      try {
        const response = await axiosInstance({
          url: 'studies/delete/obs',
          method: 'DELETE',
          data: {
            projectID,
            studyID: row?.studyID,
            roundID: row?.roundID,
            observationIDs: [row?._id],
            studyType: row?.studyType,
          },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          dispatch(getRoles({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        closeLoading();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  if (!currentStudyType) {
    return (
      <Stack minHeight={300} gap={2} direction="column" width="100%" justifyContent="center" alignItems="center">
        <img src={EmptyIcon} alt="empty" />

        <Typography variant="h5">Select a study type to get data summary</Typography>
        {singleProjectData?.studyTypes?.includes(4) ? (
          <Stack>
            <ThemeButton
              sx={{ color: getStudyColor(4), borderColor: getStudyColor(4) }}
              // onClick={() => setCurrentStudyType(3)}
              variant="outlined"
            >
              Predetermined Study
            </ThemeButton>
          </Stack>
        ) : (
          <Stack gap={2} direction="row">
            {singleProjectData?.studyTypes?.includes(1) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(1),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(1), 0.2),
                    borderColor: getStudyColor(1),
                  },
                  borderColor: getStudyColor(1),
                }}
                onClick={() => setCurrentStudyType(1)}
                variant="outlined"
              >
                Efficiency Study
              </ThemeButton>
            )}
            {singleProjectData?.studyTypes?.includes(2) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(2),
                  borderColor: getStudyColor(2),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(2), 0.2),
                    borderColor: getStudyColor(2),
                  },
                }}
                onClick={() => setCurrentStudyType(2)}
                variant="outlined"
              >
                Role Study
              </ThemeButton>
            )}
            {singleProjectData?.studyTypes?.includes(3) && (
              <ThemeButton
                sx={{
                  color: getStudyColor(3),
                  borderColor: getStudyColor(3),
                  '&:hover': {
                    bgcolor: () => alpha(getStudyColor(3), 0.2),
                    borderColor: getStudyColor(3),
                  },
                }}
                onClick={() => setCurrentStudyType(3)}
                variant="outlined"
              >
                Activity Study
              </ThemeButton>
            )}
          </Stack>
        )}
      </Stack>
    );
  }
  const handleColumnChange = (event, cell) => {
    if (currentStudyType === 1) {
      const newColSettings = {
        ...filteredColSettings,
        rasStudy: { ...filteredColSettings?.rasStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    } else if (currentStudyType === 2) {
      const newColSettings = {
        ...filteredColSettings,
        diloStudy: { ...filteredColSettings?.diloStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    } else if (currentStudyType === 3) {
      const newColSettings = {
        ...filteredColSettings,
        dotsStudy: { ...filteredColSettings?.dotsStudy, [cell?.id]: event.target.checked },
      };
      setFilteredColSettings(newColSettings);
      localStorage.setItem('dsPageColumnSettings', JSON.stringify(newColSettings));
    }
  };
  const isCellVisible = (cell) => {
    if (currentStudyType === 1) {
      return filteredColSettings?.rasStudy ? filteredColSettings?.rasStudy[cell?.id] : false;
    }
    if (currentStudyType === 2) {
      return filteredColSettings?.diloStudy ? filteredColSettings?.diloStudy[cell?.id] : false;
    }
    return filteredColSettings?.dotsStudy ? filteredColSettings?.dotsStudy[cell?.id] : false;
  };
  const closeElementModal = () => {
    setIsObsModalOpen((prevState) => false);
    // form2.reset();
  };
  const closeObsModal = () => {
    setIsObsModalOpen((prevState) => false);
    setElementCount(true);
    reset();
  };
  const addObservation = (cell) => {
    setValue('studyID', get(cell, 'studyID', ''));
    setValue('areaID', '');
    setValue('roundID', get(cell, 'roundID', ''));
    setValue('addAtObservationID', get(cell, 'observationID', ''));
    setValue('elementID', '');
    setValue('roleID', '');
    setValue('rating', '');
    setValue('frequency', '');
    setValue('notes', '');
    setIsObsModalOpen(true);
    const currentRound = singleStudyData?.rounds && singleStudyData?.rounds?.find((round) => round?.roundID === cell?.roundID);
    if (currentRound && currentRound?.data?.length > 0) {
      const filteredRolesOptions = defaultRolesOptions.filter(
        (roleOption) => !currentRound?.data?.some((round) => round?.roleID === roleOption?.value)
      );
      if (filteredRolesOptions && !!filteredRolesOptions?.length) {
        setRolesOptions(filteredRolesOptions);
      } else {
        setRolesOptions([]);
      }
    }
  };
  const frequencyInputFields = register('frequency');
  const frequencyValue = watch('frequency');
  return (
    <div>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1200px !important',
          },
        }}
        title="Create Observation"
        open={isObsModalOpen}
        onClose={closeObsModal}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Role</label>
              <CustomSelect
                name="roleID"
                label="Select Role"
                control={control}
                options={rolesOptions}
                error={!!errors?.roleID}
                helperText={errors?.roleID && errors?.roleID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Area</label>
              <CustomSelect
                name="areaID"
                label="Select Area"
                control={control}
                options={areasOptions}
                error={!!errors?.areaID}
                helperText={errors?.areaID && errors?.areaID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Element</label>
              <CustomSelect
                name="elementID"
                label="Select Element"
                control={control}
                options={elementSelectOptions}
                error={!!errors?.elementID}
                helperText={errors?.elementID && errors?.elementID?.message}
              />
            </Grid>
            <Grid sx={{ '& .MuiFormControl-root': { marginBottom: 0 } }} className="name" item xs={12} md={12} lg={6}>
              <label style={{ fontSize: '0.8600rem' }}>Rating</label>
              <CustomSelect
                name="rating"
                label="Select Rating"
                control={control}
                options={obsRatingOptions}
                error={!!errors?.rating}
                helperText={errors?.rating && errors?.rating?.message}
                isDisabled={() => elementRating === 1 || elementRating === 2}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Frequency"
                type="number"
                placeholder="Write frequency"
                disabled={!elementCount}
                InputProps={{
                  name: frequencyInputFields.name,
                  onblur: frequencyInputFields.onBlur,
                  ref: frequencyInputFields.ref,
                  value: frequencyValue,
                  onChange: (event) => {
                    const newValue = event.target.value;
                    if (newValue === '' || Number(newValue) >= 0) {
                      return frequencyInputFields.onChange(event);
                    }
                    return null;
                  },
                }}
                error={!!errors?.frequency}
                helperText={errors?.frequency && errors?.frequency?.message}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <FormInput
                label="Notes"
                placeholder="Write your notes"
                InputProps={{ ...register('notes') }}
                error={!!errors?.notes}
                helperText={errors?.notes && errors?.notes?.message}
              />
            </Grid>
          </Grid>

          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeElementModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={addObservationLoading} type="submit" variant="contained">
              Create Observation
            </ThemeButton>
          </DialogActions>
        </form>
      </ModalComponent>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack mb={2}>
          {currentStudyType && <Typography variant="h6">Data summary for {getStudyName(currentStudyType)} Study</Typography>}
          {obsLoading ? (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          ) : (
            dataSummaries && <Typography variant="subtitle2">{dataSummaries?.length} Observations</Typography>
          )}
        </Stack>
        <Stack gap={2} direction="row">
          <ThemeButton
            sx={{
              color: getStudyColor(1),
              '&:hover': {
                bgcolor: () => alpha(getStudyColor(1), 0.2),
                borderColor: getStudyColor(1),
              },
              borderColor: getStudyColor(1),
            }}
            onClick={() => setCurrentStudyType(1)}
            variant="outlined"
          >
            Efficiency Study
          </ThemeButton>
          <ThemeButton
            sx={{
              color: getStudyColor(2),
              '&:hover': {
                bgcolor: () => alpha(getStudyColor(2), 0.2),
                borderColor: getStudyColor(2),
              },
              borderColor: getStudyColor(2),
            }}
            onClick={() => setCurrentStudyType(2)}
            variant="outlined"
          >
            Role Study
          </ThemeButton>
          <ThemeButton
            sx={{
              color: getStudyColor(3),
              '&:hover': {
                bgcolor: () => alpha(getStudyColor(3), 0.2),
                borderColor: getStudyColor(3),
              },
              borderColor: getStudyColor(3),
            }}
            onClick={() => setCurrentStudyType(3)}
            variant="outlined"
          >
            Activity Study
          </ThemeButton>
        </Stack>
      </Stack>
      {summaryRows && (
        <>
          <ModalComponent
            persist
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '600px !important',
                maxHeight: '700px !important',
              },
            }}
            title="Filter"
            open={isFilterOpen}
            onClose={setIsFilterOpen}
          >
            <FilterModal
              setFilterConfig={setFilterConfig}
              filterConfig={filterConfig}
              filterOptions={filterOptions}
              closeFilter={closeFilter}
              filterBy={filterBy}
              sortBy={sortBy}
            />
          </ModalComponent>
          <ModalComponent
            persist
            PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '500px !important',
                maxHeight: '700px !important',
              },
            }}
            title="Hide/Show Columns"
            open={isColumnsModalOpened}
            onClose={setColumnsModal}
            closeButton
          >
            <Box>
              {currentStudyType &&
                (currentStudyType === 1 ? rasHeadCells : headCells)?.map(
                  (cell) =>
                    cell?.id !== 'isEc' && (
                      <ThemeSwitch
                        label={cell?.label}
                        onChange={(event) => handleColumnChange(event, cell)}
                        checked={isCellVisible(cell)}
                      />
                    )
                )}
            </Box>
          </ModalComponent>
          <DataTable
            filterKeys={['taskName', 'elementName', 'startTime', 'studyName']}
            customToolbar={Types.toolbarTypes.SINGLE_STUDY}
            headCells={finalHeadCells}
            rows={summaryRows}
            filterColumns={filterConfig?.filterColumns}
            filterBy={filterConfig?.filterBy}
            sortOrder={filterConfig?.sortOrder}
            sortBy={filterConfig?.sortBy}
            setElementFilterConfig={setFilterConfig}
            // customColumnType={study?.studyType}
            enableActions
            addObservation={addObservation}
            nestedRowKey={Types.nestedRowTypes.STUDY}
            customColumnType="studyType"
            disableNestedRowClick
            enableCheckbox
            hideNestedArrow
            nestedRowProps={nestedRowProps}
            onEditClick={onEditClick}
            isLoading={isLoading}
            toolbarProps={toolbarProps}
            onRowDelete={onDelete}
            enableDelete
            maxHeight={500}
            defaultRowsPerPage={10}
            hideCompactViewButton
            onSettingsClick={() => setColumnsModal(true)}
          />
        </>
      )}
    </div>
  );
};

export default DataSummaryPage;

DataSummaryPage.propTypes = {
  projectID: PropTypes.string,
  singleProjectData: PropTypes.object,
};
