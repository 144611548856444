import { Icon } from '@iconify/react/dist/iconify';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Box, Card, Container, DialogActions, Drawer, Grid, Link, Skeleton, Stack, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { get, has } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ModalComponent from '../../components/ModalComponent';
import { ThemeButton } from '../../components/button';
import { ReusableTabs } from '../../components/reusableTabs';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import { toggleDownload } from '../../layout/dashboard/dashboardSlice';
import { APP_ROUTER_BASE_URL } from '../../utils';
import GroupRulesPage from '../groupRole';
import NotesPage from '../notes/notesPage';
import ProjectLogsPage from '../projectLogs/projectLogsPage';
import QuestionPage from '../question';
import RemindersPage from '../reminder/RemindersPage';
import StudyPage from '../studiesPage';
import StudyLogsPage from '../studieslogs/studyLogsPage';
import TasksListPage from '../tasksPage/TasksListPage';
import DownloadComponent from './DownloadComponent';
import OverViewPage from './OverViewPage';
import AreasPage from './areas/AreasPage';
import CategoriesPage from './category/CategoriesPage';
import DataSummaryPage from './dataSummary/DataSummaryPage';
import ElementsPage from './elements/ElementsPage';
import FieldUser from './fieldUser/FieldUser';
import LocationsPage from './location/LocationsPage';
import { downloadCoverageReport, downloadCrib, downloadProject, getSingleProject } from './projectSlice';
import RolesPage from './roles/RolesPage';

const SingleProjectPage = () => {
  const { singleProjectData, isSingleProjectLoading, isProjectDownloading, isCoverageDownloading, isCribDownloading } =
    useSelector((state) => state.project);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const decoded = jwtToken && jwtDecode(jwtToken);
  const role = decoded?.role || '0';
  const location = useLocation();
  const locationState = location.state;
  const { isDownloadOpen } = useSelector((state) => state.dashboard);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalopen, setisModalopen] = useState(false);
  const tabs = [
    { label: 'Overview', value: 'overview' },
    { label: 'Field Users', value: 'fieldUsers' },
    { label: 'Locations', value: 'location' },
    { label: 'Categories', value: 'categories' },
    { label: 'Areas', value: 'areas' },
    { label: 'Roles', value: 'roles' },
    { label: 'Elements', value: 'elements' },
    { label: 'Tasks', value: 'tasks' },
    { label: 'Studies', value: 'studies' },
    { label: 'Data Summary', value: 'dataSummary' },
    { label: 'Notes', value: 'notes' },
    { label: 'Groups', value: 'groupRules' },
    { label: 'Questions', value: 'question' },
    { label: 'Reminders', value: 'reminders' },
    { label: 'Audit', value: 'logs' },
    { label: 'Study Audit', value: 'studyLogs' },
  ];
  const fieldUserTabs = [
    { label: 'Studies', value: 'studies' },
    { label: 'Data Summary', value: 'dataSummary' },
    { label: 'Notes', value: 'notes' },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (locationState?.isNewProject) {
      setisModalopen(true);
    }
  }, [locationState?.isNewProject]);
  useEffect(() => {
    if (id) {
      dispatch(getSingleProject(id));
    }
  }, [dispatch, id]);
  const [selectedTab, setSelectedTab] = useState('studies');
  const onTabChange = (event, newValue) => setSelectedTab(newValue);
  const navigate = useNavigate();
  const navigateToEditPage = () => {
    if (id) {
      navigate(`${APP_ROUTER_BASE_URL}project/edit/${id}`);
    }
  };
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <OverViewPage projectID={singleProjectData?._id} />;
      case 'fieldUsers':
        return (
          <FieldUser
            projectID={singleProjectData?._id}
            customerID={singleProjectData?.customerID}
            customerName={singleProjectData?.customerName}
            project={singleProjectData}
          />
        );
      case 'tasks':
        return <TasksListPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studies':
        return <StudyPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'groupRules':
        return <GroupRulesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'question':
        return <QuestionPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'reminders':
        return <RemindersPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'categories':
        return <CategoriesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'areas':
        return <AreasPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'roles':
        return <RolesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'elements':
        return <ElementsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'dataSummary':
        return (
          <DataSummaryPage
            singleProjectData={singleProjectData}
            customerID={singleProjectData?.customerID}
            projectID={singleProjectData?._id}
          />
        );
      case 'notes':
        return <NotesPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'logs':
        return <ProjectLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'studyLogs':
        return <StudyLogsPage customerID={singleProjectData?.customerID} projectID={singleProjectData?._id} />;
      case 'location':
        return <LocationsPage projectID={singleProjectData?._id} customerID={singleProjectData?.customerID} />;
      default:
        return null;
    }
  };
  const closeDrawer = () => setIsDrawerOpen(false);
  const openDrawer = () => setIsDrawerOpen(true);
  // const toggleDrawer = () => setIsDrawerOpen((prev) => !prev);
  const toggleDrawer = () => dispatch(toggleDownload());
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
  };
  const openModal = () => {
    setisModalopen(true);
  };
  const handleDownloadStats = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadProject(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const handleDownloadCrib = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadCrib(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };
  const handleCoverageReport = async () => {
    const payload = {
      projectID: get(singleProjectData, '_id'),
      customerID: get(singleProjectData, 'customerID'),
      projectName: get(singleProjectData, 'name'),
      customerName: get(singleProjectData, 'customerName'),
    };
    dispatch(downloadCoverageReport(payload))
      .then((response) => {
        if (response.payload?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'payload.message', 'Click download links to see files'),
            })
          );
        } else if (get(response, 'payload.message')) {
          const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: errorMessage,
            })
          );
        }
      })
      .catch((error) => {
        console.log('error==> ', error);
      });
  };

  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 'fit-content !important',
            maxHeight: '700px',
          },
        }}
        title="Import Project"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ my: 2, px: 1, mx: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} type="submit" variant="contained">
              {/* {rowToEdit ? 'Save Changes' : 'Create industry'} */}
              Import Project
            </ThemeButton>
          </DialogActions>
        }
      >
        <Stack direction="column" justifyContent="center" gap={2} my={1}>
          {/* <Typography variant="h6">Import Project</Typography> */}
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 1:{' '}
            <Typography variant="body1">
              Click <Link component="button">here</Link> to download the latest version of the import template.
            </Typography>
          </Typography>
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 2: <Typography variant="body1">Populate the downloaded file following the instructions within.</Typography>
          </Typography>
          <Typography variant="bold" style={{ display: 'flex', gap: '10px', alignItems: 'end' }}>
            Step 3:{' '}
            <Typography variant="body1">
              Once is ready, Click <Link component="button">here</Link> to select your populated file.
            </Typography>
          </Typography>
        </Stack>
      </ModalComponent>
      <Drawer sx={{ position: 'unset' }} hideBackdrop anchor="right" open={isDownloadOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            px: 1,
            // borderRight: '1px solid #ececec',
            pl: 0,
            display: 'flex',
            width: 600,
            bgcolor: '#f1f1f1',
            height: '100vh',
            overflowY: 'auto',
          }}
        >
          <DownloadComponent />
        </Box>
      </Drawer>
      <Container maxWidth="xxl">
        <Grid sx={{ width: '100%', mb: 2 }} alignItems="center" spacing={2} container justifyContent="space-between">
          <Grid item lg={3} sm={3} xs={12}>
            <Typography variant="h4">
              {isSingleProjectLoading ? (
                <Skeleton width={300} />
              ) : (
                has(singleProjectData, 'name') && get(singleProjectData, 'name')
              )}
            </Typography>
          </Grid>
          <Grid sx={{}} item lg={9} md={9} xs={12}>
            <Grid justifyContent="flex-end" spacing={2} container>
              {(role === 1 || role === 2) && (
                <Grid item lg={2} md={2} sm={2} xs={12}>
                  <ThemeButton
                    onClick={navigateToEditPage}
                    size="small"
                    startIcon={<Icon icon="eva:edit-fill" />}
                    fullWidth
                    variant="contained"
                  >
                    Edit Project
                  </ThemeButton>
                </Grid>
              )}
              {(role === 1 || role === 2) && (
                <Grid item lg={2} md={2} sm={2} xs={12}>
                  <ThemeButton
                    size="small"
                    startIcon={<Icon width={16} icon="fa-solid:file-export" />}
                    fullWidth
                    variant="outlined"
                    onClick={handleDownloadStats}
                    loading={isProjectDownloading}
                  >
                    Get Statistics
                  </ThemeButton>
                </Grid>
              )}
              <Grid item lg={2} md={2} sm={2} xs={12}>
                <ThemeButton
                  onClick={handleDownloadCrib}
                  size="small"
                  startIcon={<Icon width={16} icon="fa-solid:download" />}
                  fullWidth
                  variant="outlined"
                  loading={isCribDownloading}
                >
                  Download Crib
                </ThemeButton>
              </Grid>
              <Grid item lg={2} md={2} sm={2} xs={12}>
                <ThemeButton
                  size="small"
                  startIcon={<Icon width={16} icon="fa-solid:file-export" />}
                  fullWidth
                  variant="outlined"
                  loading={isCoverageDownloading}
                  onClick={handleCoverageReport}
                >
                  Coverage Report
                </ThemeButton>
              </Grid>
              {(role === 1 || role === 2) && (
                <Grid item lg={2} md={2} sm={2} xs={12}>
                  <ThemeButton
                    size="small"
                    startIcon={<Icon width={16} icon="fa-solid:file-export" />}
                    fullWidth
                    variant="outlined"
                    onClick={openModal}
                  >
                    Import Project
                  </ThemeButton>
                </Grid>
              )}
              <Grid item lg={2} md={2} sm={2} xs={12}>
                <ThemeButton
                  fullWidth
                  startIcon={<FileDownloadOutlinedIcon />}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={toggleDrawer}
                >
                  Downloads
                </ThemeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card>
          <Card sx={{ borderBottom: '1px solid #eee' }}>
            <ReusableTabs
              tabs={role === 1 || role === 2 ? tabs : fieldUserTabs}
              selectedTab={selectedTab}
              handleChange={onTabChange}
            />
          </Card>
          <Box sx={{ p: 2, bgcolor: '#f9fafb', width: '100%' }}>
            {isSingleProjectLoading ? <Skeleton variant="rectangular" width="100%" height={300} /> : renderTabContent()}
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default SingleProjectPage;
