import DownloadIcon from '@mui/icons-material/Download';
import { FormControl, Grid, InputAdornment, Link, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColorLegend from '../../components/ColorLegend';
import Iconify from '../../components/Iconify';
import ModalComponent from '../../components/ModalComponent';
import { getStudyColor, getStudyLabel } from '../../components/StudiesComponent';
import { ActionButton, ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import axiosInstance from '../../utils/axiosInstance';
import ConfirmDialog from '../../components/ConfirmDialog';
import { downloadProjectPhotos } from './studiesSlice';

const StudiesToolbar = (props) => {
  const {
    handleOpenBackdrop,
    handleCloseBackdrop,
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    setPage,
  } = props;
  const { singleProjectData, isSingleProjectLoading } = useSelector((state) => state.project);
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, handleReviewClicked, projectID, getStudiesPayload, fetchStudies } =
    toolbarProps;
  const [isModalopen, setIsModalOpen] = useState(false);
  const [downloadModalType, setDownloadModalType] = useState('study');
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleDownloadClicked = (type) => {
    setDownloadModalType(type);
    openModal();
  };
  const dispatch = useDispatch();
  const onConfirmDelete = async () => {
    if (selected && selected?.length > 0) {
      handleOpenBackdrop();
      setIsDeleteModalOpen(false);

      try {
        const payload = getStudiesPayload(selected);
        const response = await axiosInstance({
          url: 'studies/delete',
          method: 'DELETE',
          data: payload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          setIsDeleteModalOpen(false);
          handleClearSelection();
          fetchStudies();
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        setIsDeleteModalOpen(false);
        handleClearSelection();
        handleCloseBackdrop();
      }
    }
  };
  const handleSelectionClick = async (selectedIds, status) => {
    if (!status) {
      console.log('No status provided');
      return;
    }
    if (status === 'delete') {
      setIsDeleteModalOpen(true);
    }
  };
  const downloadProjectStudy = async (studyType) => {
    if (downloadModalType === 'photos') {
      const payload = {
        studyType,
        projectID: get(singleProjectData, '_id'),
        customerID: get(singleProjectData, 'customerID'),
        projectName: get(singleProjectData, 'name'),
        customerName: get(singleProjectData, 'customerName'),
      };
      dispatch(downloadProjectPhotos(payload))
        .then((response) => {
          if (response.payload?.success) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
              })
            );
            closeModal();
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  return (
    <>
      <ConfirmDialog
        title="Delete"
        open={isDeleteModalOpen}
        setOpen={setIsDeleteModalOpen}
        selectedField={selected}
        onConfirm={onConfirmDelete}
        confirmText="Delete"
      >
        <Typography variant="body1">
          Are you sure you want to <b>delete</b> this?
        </Typography>
      </ConfirmDialog>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '300px !important',
            maxHeight: '700px',
          },
        }}
        title={`Download ${downloadModalType === 'study' ? 'Studies' : 'Photos'}`}
        open={isModalopen}
        onClose={closeModal}
        className="download-studies-modal"
      >
        <Stack mb={2} direction="column" gap>
          <Link
            onClick={() => downloadProjectStudy(99)}
            component="button"
            variant="subtitle1"
            sx={{ color: '#0bbbef', textDecorationColor: '#00c0f300' }}
            align="center"
          >
            All Studies
          </Link>
          {singleProjectData?.studyTypes?.length > 0 &&
            singleProjectData?.studyTypes?.map((studyType) => (
              <Link
                onClick={() => downloadProjectStudy(studyType)}
                component="button"
                variant="subtitle1"
                sx={{ color: getStudyColor(studyType), textDecorationColor: '#00c0f300' }}
                align="center"
              >
                {getStudyLabel(studyType)}
              </Link>
            ))}
        </Stack>
        <Stack style={{ display: 'flex', justifyContent: 'center' }} direction="row">
          <ThemeButton variant="outlined" onClick={closeModal} style={{ width: 'fit-content' }}>
            Cancel
          </ThemeButton>
        </Stack>
      </ModalComponent>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ActionButton
              type="delete"
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="error"
              onClick={() => handleSelectionClick && handleSelectionClick(selected, 'delete', handleClearSelection)}
            >
              {`Delete ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
            <ActionButton
              type=""
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              // color="error"
              onClick={() => handleReviewClicked && handleReviewClicked(selected, handleClearSelection)}
            >
              Mark as Reviewed
            </ActionButton>
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={3} xl={3} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search Studies'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} xl={3} md={4} xs={12}>
              <ColorLegend color="warning.main" label="Rows with duplicate entries" />
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={5} lg={5}>
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                <ThemeButton
                  size="small"
                  onClick={() => handleDownloadClicked('study')}
                  startIcon={<DownloadIcon />}
                  variant="outlined"
                >
                  Download Studies
                </ThemeButton>
                <ThemeButton
                  size="small"
                  onClick={() => handleDownloadClicked('photos')}
                  startIcon={<DownloadIcon />}
                  variant="outlined"
                >
                  Download Photos
                </ThemeButton>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

StudiesToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
};

export default StudiesToolbar;
