// component
import SvgColor from '../../../components/svgColor';
import { APP_ROUTER_BASE_URL } from '../../../utils';

// ----------------------------------------------------------------------

export const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
export const NAV_WIDTH = 280;
export const drawerWidth = 200;
// Reports, Customers, Projects, Users, Admin, Data, Settings
const navConfig = [
  // {
  //   title: 'dashboard',
  //   // isSuper:'true',
  //   path: `${APP_ROUTER_BASE_URL}`,
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Reports',
    // isSuper:'true',
    path: `${APP_ROUTER_BASE_URL}reports`,
    icon: icon('ic_user'),
    allowedRoles: ['1', '2'],
  },
  {
    title: 'Customers',
    // isSuper:'true',
    path: `${APP_ROUTER_BASE_URL}customer`,
    icon: icon('ic_college_group'),
    allowedRoles: ['1'],
  },
  {
    title: 'Projects',
    // isSuper:'true',
    path: `${APP_ROUTER_BASE_URL}project`,
    icon: icon('ic_college'),
    allowedRoles: ['1', '2', '3'],
  },
  {
    title: 'Users',
    // isSuper:'true',
    // path: `${APP_ROUTER_BASE_URL}user`,
    shadowPath: `${APP_ROUTER_BASE_URL}user`,
    icon: icon('ic_campus'),
    allowedRoles: ['1', '2'],
    children: [
      {
        title: 'Super User',
        path: `${APP_ROUTER_BASE_URL}user/super`,
        allowedRoles: ['1'],
      },
      {
        title: 'Admin User',
        path: `${APP_ROUTER_BASE_URL}user/admin`,
        allowedRoles: ['1', '2'],
      },
      {
        title: 'Field User',
        path: `${APP_ROUTER_BASE_URL}user/field`,
        allowedRoles: ['1', '2'],
      },
    ],
  },
  {
    title: 'System',
    icon: icon('ic_career'),
    // path: `${APP_ROUTER_BASE_URL}admin`,
    shadowPath: `${APP_ROUTER_BASE_URL}admin`,
    allowedRoles: ['1', '2'],
    children: [
      { title: 'Categories', path: `${APP_ROUTER_BASE_URL}admin/category`, allowedRoles: ['1', '2'] },
      { title: 'Industries', path: `${APP_ROUTER_BASE_URL}admin/industry`, allowedRoles: ['1', '2'] },
      { title: 'Sectors', path: `${APP_ROUTER_BASE_URL}admin/sector`, allowedRoles: ['1', '2'] },
      // { title: 'Areas', path: `${APP_ROUTER_BASE_URL}admin/area`, allowedRoles: ['1', '2'] },
      // { title: 'Roles', path: `${APP_ROUTER_BASE_URL}admin/role`, allowedRoles: ['1', '2'] },
      { title: 'Elements', path: `${APP_ROUTER_BASE_URL}admin/element`, allowedRoles: ['1', '2'] },
      // { title: 'Tasks', path: `${APP_ROUTER_BASE_URL}admin/task`, allowedRoles: ['1', '2'] },
      // { title: 'Groups', path: `${APP_ROUTER_BASE_URL}admin/group, allowedRoles: ['1', '2']` },
      // { title: 'Tags', path: `${APP_ROUTER_BASE_URL}admin/tag`, allowedRoles: ['1', '2'] },
      // { title: 'Certifications', path: `${APP_ROUTER_BASE_URL}admin/certification`, allowedRoles: ['1', '2'] },
      // { title: 'Locations', path: `${APP_ROUTER_BASE_URL}admin/location, allowedRoles: ['1', '2']` },
    ],
  },
  {
    title: 'Data',
    path: `${APP_ROUTER_BASE_URL}data/hours-mapping`,
    icon: icon('ic_courses'),
    allowedRoles: ['1'],
  },

  {
    title: 'Settings',
    path: `${APP_ROUTER_BASE_URL}app-settings`,
    icon: icon('ic_skills'),
    allowedRoles: ['1'],
  },
];

export default navConfig;
