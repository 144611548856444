import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Card,
  Collapse,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Iconify from '../../../components/Iconify';
import ModalComponent from '../../../components/ModalComponent';
import { ActionButton, ThemeButton } from '../../../components/button';
import StatusButtons from '../../../components/buttonGroup';
import CheckboxGroup from '../../../components/checkbox/CheckboxGroup';
import GroupCheckbox from '../../../components/formComponents/FormCheckbox';
import FormInput from '../../../components/formComponents/FormInput';
import FormRadioGroup from '../../../components/formComponents/formRadioGroup';
import CustomSelect from '../../../components/select';
import { setSnackbar } from '../../../components/snackbar/snackbarSlice';
import ThemeSwitch from '../../../components/switch';
import { Types } from '../../../types';
import { filterEmptyValues } from '../../../utils';
import axiosInstance from '../../../utils/axiosInstance';
import { addSystemElementSchema } from '../../../utils/schema';
import { SortableElement } from '../../tasksPage/TasksNestedRow';
import { addElement, getElements, massEditElements } from './elementsSlice';
import ConfirmDialog from '../../../components/ConfirmDialog';

const ElementToolbar = (props) => {
  const {
    selected,
    filterName,
    onFilterName,
    searchLable,
    toolbarProps,
    handleClearSelection,
    setPage,
    handleOpenBackdrop,
    handleCloseBackdrop,
    statusUpdateUrl,
    rows,
  } = props;
  const numSelected = selected?.length;
  const { onCancel, onSave, placeholder, addButtonName, getPayload, fetchData, categoryOptions, tasksOptions } = toolbarProps;
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };
  const { singleProjectData, isSingleProjectLoading, isAdding } = useSelector((state) => state.project);
  const cookies = new Cookies();
  const jwtToken = cookies.get('token');
  const Role = jwtToken && jwtDecode(jwtToken);
  const role = String(Role?.role) || '0';
  const canAddSystemElement = useMemo(() => {
    if (singleProjectData?.addSystemElement && role === '1') {
      return true;
    }
    return false;
  }, [role, singleProjectData]);

  const [isModalopen, setisModalopen] = useState(false);
  const [isSystemElementModal, setSystemElementModal] = useState(false);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [collapsedRow, setCollapsedRow] = useState('');
  const [rowToEdit, setRowToEdit] = useState('');
  const { tasks } = useSelector((state) => state.tasks);
  const { elements, isLoading, addElementLoading } = useSelector((state) => state.projectElements);
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('active');
  const handleStatusButtonClick = (selectedIds, status) => {
    setCurrentStatus(() => status);
    setIsModalOpen(() => true);
  };
  const handleSelectionClick = async (selectedIds, status) => {
    setIsModalOpen(false);
    if (!status) {
      // also check if status is archive or active then only make api call
      console.log('No status provided');
      return;
    }
    if (statusUpdateUrl && selectedIds && selectedIds?.length > 0) {
      const initialPayload = { areaIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: statusUpdateUrl,
          method: 'PATCH',
          data: getPayload ? getPayload(selected, status, id) : initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          if (fetchData) {
            fetchData();
          }
          // dispatch(getAreas({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  const studyTypeConfig = { label: 'label', value: 'value' };
  const studyTypeOptions = [
    { label: 'Efficiency Study', value: 1, color: '#f59d0f' },
    { label: 'Role Study', value: 2, color: '#784fc2' },
    { label: 'Activity Study', value: 3, color: '#0ba0ef' },
  ];
  const {
    elements: systemElements,
    isLoading: isSystemLoading,
    isAdding: isSystemAdding,
  } = useSelector((state) => state.element);
  useEffect(() => {
    dispatch(getElements({ defaultEntry: true }));
  }, [dispatch]);
  const systemElementOptions = useMemo(
    () =>
      systemElements
        ?.map((el) => ({ label: el?.name, value: el?._id }))
        ?.filter((element) => !rows?.some((row) => row?._id === element?.value)),
    [rows, systemElements]
  );
  const form = useForm({
    defaultValues: {
      // defaultEntry: true,
      unitOfMeasure: '',
      studyTypes: [],
      tasks: [],
      rating: 3,
      type: 2,
      count: false,
      contingencyAllowance: null,
      relaxationAllowance: null,
      categoryID: '',
    },
    // resolver: yupResolver(addElementInProjectSchema),
  });
  const { register, watch, handleSubmit, formState, reset, getValues, setValue, control } = form;
  const { errors } = formState;
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'tasks', // unique name for your Field Array
  });
  const studyTypesValues = watch('studyTypes');
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const closeModal = () => {
    setisModalopen((prevState) => !prevState);
    reset();
    // reset({ permission: '' });
    // setShowPassword(false);
    setValue('tasks', []);
    setOriginalTasks([]);
    if (rowToEdit) {
      setTimeout(() => {
        setRowToEdit('');
      }, 200);
    }
  };
  const closeElementModal = () => {
    setSystemElementModal((prevState) => !prevState);
    form2.reset();
  };
  const tempID = useMemo(() => 'tempID', []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentElementname, setCurrentElementName] = useState('');
  const open = Boolean(anchorEl);
  const addTaskClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectTask = (event, value) => {
    const selectedTask = tasks.find((task) => task?._id === value);
    if (selectedTask) {
      const newTask = {
        taskID: selectedTask?._id,
        taskName: selectedTask?.name,
        elements:
          rowToEdit && selectedTask?.projectSetting?.elements.includes(rowToEdit)
            ? get(selectedTask, 'projectSetting.elements', [])
            : [tempID, ...get(selectedTask, 'projectSetting.elements', [])],
      };
      append(newTask);
    }
    handleClose();
  };
  const typeOptions = useMemo(
    () => [
      { label: 'Fixed', value: 1 },
      { label: 'Variable', value: 2 },
    ],
    []
  );
  const ratingOptions = useMemo(
    () => [
      {
        label: 'Not Rated',
        description: 'No rating screen in app, SMV not shown in CMS or in any values. Only in data summary export',
        value: 1,
      },
      { label: '100', description: 'No rating screen in app, element defaults to 100 rating', value: 2 },
      { label: 'Field User Input', description: 'Show rating screen in the app for the element', value: 3 },
    ],
    []
  );
  const statusOptions = useMemo(
    () => [
      { label: 'Active', value: Types.ACTIVE },
      { label: 'Archive', value: Types.ARCHIVED },
    ],
    []
  );
  const tasksValues = watch('tasks');
  const handleDragEnd = (event, field, index) => {
    const { active, over } = event;
    const currentElements = field?.elements;
    const activeIndex = currentElements?.findIndex((element) => element === active.id);
    const overIndex = currentElements?.findIndex((element) => element === over.id);
    const sortedTempArray = arrayMove(currentElements, activeIndex, overIndex);
    const taskValToUpdate = [...tasksValues];
    taskValToUpdate[index].elements = sortedTempArray;
    setValue('tasks', taskValToUpdate);
  };
  const rowClicked = (element) => {
    if (collapsedRow === element) {
      setCollapsedRow('');
    } else {
      setCollapsedRow(element);
    }
  };

  const onSubmit = (values) => {
    if (rows && rows?.length > 0 && selected && selected?.length > 0) {
      const payload = selected?.map((selectedId) => {
        const observation = rows?.find((row) => row?._id === selectedId) || null;
        return observation;
      });
      const finalPayload = payload?.map((observation) => {
        const obsObject = {
          categoryID: values?.categoryID || observation?.categoryID,
          unitOfMeasure: values?.unitOfMeasure || observation?.projectSetting?.unitOfMeasure,
          studyTypes: values?.studyTypes?.length > 0 ? values?.studyTypes : observation?.projectSetting?.studyTypes,
          rating: values?.rating || observation?.projectSetting?.rating,
          type: values?.type || observation?.projectSetting?.type,
          count: values?.count || observation?.projectSetting?.count,
          contingencyAllowance: values?.contingencyAllowance || observation?.projectSetting?.contingencyAllowance,
          relaxationAllowance: values?.relaxationAllowance || observation?.projectSetting?.relaxationAllowance,
          tasks: values?.studyTypes?.length > 0 ? values?.tasks : null,
          defaultEntry: false,
          _id: observation?._id,
          name: observation?.name,
          customerID: observation?.projectSetting?.customerID,
          projectID: id,
        };
        const filteredPayload = filterEmptyValues(obsObject);
        return filteredPayload;
      });
      dispatch(massEditElements(finalPayload))
        .then((response) => {
          if (response?.payload.successCount <= 0) {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          } else {
            dispatch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message'),
              })
            );
          }
          closeModal();
          handleClearSelection();
          fetchData();
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'error',
              snackbarMessage: 'Something went wrong',
            })
          );
        });
    }
  };
  const form2 = useForm({
    defaultValues: {
      elementID: '',
    },
    resolver: yupResolver(addSystemElementSchema),
  });

  const onElementSubmit = (values) => {
    const currentSystemElement = systemElements?.find((element) => element?._id === values?.elementID);
    if (currentSystemElement) {
      const payload = {
        defaultEntry: false,
        name: currentSystemElement?.name,
        projectID: singleProjectData?._id,
        customerID: singleProjectData?.customerID,
        categoryID: get(currentSystemElement, 'projectSetting.categoryID'),
        contingencyAllowance: get(currentSystemElement, 'projectSetting.contingencyAllowance'),
        relaxationAllowance: get(currentSystemElement, 'projectSetting.relaxationAllowance'),
        studyTypes: get(currentSystemElement, 'projectSetting.studyTypes'),
      };

      if (singleProjectData?.customerID && singleProjectData?._id) {
        dispatch(addElement(payload))
          .then((response) => {
            if (response.payload?.success) {
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'success',
                  snackbarMessage: get(response, 'payload.message', 'Element Added Successfully'),
                })
              );
              fetchData();
              closeElementModal();
            } else if (get(response, 'payload.message')) {
              const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
              dispatch(
                setSnackbar({
                  snackbarOpen: true,
                  snackbarType: 'error',
                  snackbarMessage: errorMessage,
                })
              );
            }
          })
          .catch((error) => {
            console.log('error==> ', error);
          });
      }
    }
  };
  const handleDeleteElements = async () => {
    if (selected?.length > 0) {
      handleOpenBackdrop();
      setIsModalOpen(false);
      try {
        const response = await axiosInstance({
          url: `elements/delete/${selected[0]}`,
          method: 'DELETE',
          data: { projectID: id, defaultEntry: false, elementIDs: selected },
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          handleClearSelection();
          dispatch(getElements({ projectID: id, defaultEntry: false }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '1400px !important',
          },
        }}
        title="Mass Edit Elements"
        open={isModalopen}
        onClose={closeModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, px: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} onClick={handleSubmit(onSubmit)} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid rowSpacing={1} columnSpacing={3} container>
            <Grid className="left-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                <Grid className="unit-of-measures" item xs={12} md={6}>
                  <FormInput
                    label="Unit of Measure"
                    placeholder="e.g Per item"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('unitOfMeasure'),
                    }}
                    width="100%"
                    error={!!errors?.unitOfMeasure}
                    helperText={errors?.unitOfMeasure && errors?.unitOfMeasure?.message}
                  />
                </Grid>
                <Grid className="category" item xs={12} md={6}>
                  <label htmlFor="categoryID" id="category-select" style={{ fontSize: '0.8600rem' }}>
                    Category
                  </label>
                  <CustomSelect
                    id="categoryID"
                    width="100%"
                    name="categoryID"
                    // InputProps={{ ...register('customerID') }}
                    control={control}
                    options={categoryOptions || []}
                    label="Select a category"
                    // value={watch('customerID')}
                    error={!!errors?.categoryID}
                    helperText={errors?.categoryID && errors?.categoryID?.message}
                    // placeholder=Chooseexpiration date"
                  />
                </Grid>
                <Grid className="type" item xs={12} md={4}>
                  <Box
                    sx={{
                      // '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      mt: 1,
                    }}
                  >
                    <GroupCheckbox error={!!errors?.type} helperText={errors?.type && errors?.type?.message} label="Type">
                      <FormRadioGroup row fontSize={18} options={typeOptions} control={control} name="type" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
                <Grid mt={1} item xs={12} md={4}>
                  <FormInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    type="number"
                    label="Relaxation Allowance"
                    placeholder="e.g 1"
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('relaxationAllowance'),
                    }}
                    width="100%"
                    error={!!errors?.relaxationAllowance}
                    helperText={errors?.relaxationAllowance && errors?.relaxationAllowance?.message}
                  />
                </Grid>
                <Grid mt={1} item xs={12} md={4}>
                  <FormInput
                    type="number"
                    label="Contingency Allowance"
                    placeholder="e.g 1"
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    // fontSize="0.9375rem"
                    // fontWeight={600}
                    InputProps={{
                      ...register('contingencyAllowance'),
                    }}
                    width="100%"
                    error={!!errors?.contingencyAllowance}
                    helperText={errors?.contingencyAllowance && errors?.contingencyAllowance?.message}
                  />
                </Grid>
                <Grid className="count" item xs={12} md={12}>
                  <ThemeSwitch
                    description="Relevant only on Role and Activity studies"
                    label="Count"
                    control={control}
                    name="count"
                    width="100%"
                  />
                </Grid>
                <Grid className="study-types" item xs={12} md={12}>
                  <CheckboxGroup
                    row
                    control={control}
                    label="Study Types"
                    name="studyTypes"
                    options={studyTypeOptions}
                    config={studyTypeConfig}
                  />
                </Grid>
                <Grid className="rating" item xs={12} md={12}>
                  <Box
                    sx={{
                      '& .css-dmmspl-MuiFormGroup-root': { mt: 0.5 },
                      '& .css-14r9679-MuiFormLabel-root.Mui-focused': { color: '#212B36' },
                      '& .css-14r9679-MuiFormLabel-root': { color: '#212B36', fontWeight: '600' },
                      '& .MuiFormLabel-root .Mui-error ': { color: '#212B36', fontWeight: '600' },
                      width: '80%',
                    }}
                  >
                    <GroupCheckbox
                      error={!!errors?.rating}
                      helperText={errors?.rating && errors?.rating?.message}
                      label="Default Rating"
                    >
                      <FormRadioGroup fontSize={18} options={ratingOptions} control={control} name="rating" />
                    </GroupCheckbox>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="right-side" item xs={12} md={6} lg={6}>
              <Grid rowSpacing={1} columnSpacing={1} container>
                {studyTypesValues && (studyTypesValues.includes(2) || studyTypesValues.includes(3)) && (
                  <Grid item xs={12} lg={12} md={12}>
                    {/* <FormHelperText style={{ fontSize: '0.8600rem', marginBottom: 8, fontWeight: 'unset' }}>Tasks</FormHelperText> */}
                    <Stack mb={2} justifyContent="space-between" direction="row">
                      <Stack width="100%" direction="column">
                        <Typography variant="subtitle2">Tasks</Typography>
                        {get(errors, 'elements.root.message') && (
                          <Typography sx={{ color: 'error.main' }} variant="caption">
                            {get(errors, 'elements.root.message')}
                          </Typography>
                        )}
                      </Stack>
                    </Stack>
                    {fields?.length > 0 ? (
                      <Stack>
                        {fields.map((field, index) => (
                          <Stack key={field?.id} width="100%" direction="column" gap mb={1}>
                            <Stack gap direction="row" width="100%">
                              <Box width="100%">
                                <Card
                                  onClick={() => rowClicked(field?.taskID)}
                                  sx={{
                                    cursor: 'pointer',
                                    p: 2,
                                    bgcolor: '#f5f5f5f0',
                                    '&:hover': {
                                      backgroundColor: '#ebe9e9f0',
                                    },
                                  }}
                                >
                                  <Stack width="100%" alignItems="center" justifyContent="space-between" direction="row">
                                    <Typography fontWeight="bold" variant="caption">
                                      {field?.taskName}
                                    </Typography>
                                    {collapsedRow === field?.taskID ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </Stack>
                                </Card>
                              </Box>
                              <IconButton onClick={() => remove(index)} sx={{ mt: 0 }}>
                                <CancelIcon color="error" />
                              </IconButton>
                            </Stack>

                            <Collapse in={collapsedRow === field?.taskID}>
                              <DndContext
                                modifiers={[restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
                                // autoScrol={false}
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={(event) => handleDragEnd(event, field, index)}
                              >
                                {get(tasksValues, `${index}.elements`) && (
                                  <SortableContext
                                    items={get(tasksValues, `${index}.elements`, [])}
                                    strategy={verticalListSortingStrategy}
                                  >
                                    <Box
                                      py={2}
                                      sx={{ bgcolor: '#f5f5f5f0', overflowY: 'auto', maxHeight: 300, width: 'calc(100% - 50px)' }}
                                    >
                                      {get(tasksValues, `${index}.elements`)?.length > 0 &&
                                        tasksValues[index]?.elements?.map((element) => {
                                          const elementFound = elements.find((value) => value?._id === element);
                                          if (elementFound || element === tempID) {
                                            return (
                                              <SortableElement sx={{ cursor: 'grab !important' }} id={element} key={element}>
                                                <Box
                                                  gap={2}
                                                  p={2}
                                                  my={1}
                                                  width={500}
                                                  height={20}
                                                  alignItems="center"
                                                  display="flex"
                                                >
                                                  <DragIndicatorIcon sx={{ color: '#636c72ed' }} fontSize="small" />
                                                  <Typography
                                                    sx={{ color: element === tempID ? 'primary.main' : 'text.main' }}
                                                    variant="subtitle2"
                                                  >
                                                    {element === tempID
                                                      ? currentElementname || 'Current Element'
                                                      : elements.find((value) => value?._id === element)?.name}
                                                  </Typography>
                                                </Box>
                                              </SortableElement>
                                            );
                                          }
                                          return null;
                                        })}
                                    </Box>
                                  </SortableContext>
                                )}
                              </DndContext>
                            </Collapse>
                          </Stack>
                        ))}
                      </Stack>
                    ) : null}
                    <Stack direction="row" width="100%" justifyContent="flex-end">
                      <ThemeButton onClick={addTaskClick}>Add Task</ThemeButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        // PaperProps={{
                        //   style: {
                        //     maxHeight: ITEM_HEIGHT * 4.5,
                        //     width: '20ch',
                        //   },
                        // }}
                      >
                        {tasksOptions && tasksOptions?.length > 0 ? (
                          tasksOptions.map((option) => (
                            <MenuItem
                              value={option?.value}
                              key={option?.value}
                              onClick={(event) => handleSelectTask(event, option?.value)}
                              disabled={tasksValues.some((task) => task?.taskID === option?.value)}
                            >
                              {option?.label}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>
                            <i>No task available</i>
                          </MenuItem>
                        )}
                      </Menu>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </ModalComponent>
      <ModalComponent
        persist
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px !important',
          },
        }}
        title="Add System Element"
        open={isSystemElementModal}
        onClose={closeElementModal}
        DialogActions={
          <DialogActions sx={{ mt: 2, p: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ThemeButton type="button" onClick={closeElementModal} color="error" variant="outlined">
              Cancel
            </ThemeButton>
            <ThemeButton loading={false} onClick={form2?.handleSubmit(onElementSubmit)} type="submit" variant="contained">
              Save Changes
            </ThemeButton>
          </DialogActions>
        }
      >
        <form onSubmit={form2?.handleSubmit(onElementSubmit)}>
          <label htmlFor="elementID" id="category-select" style={{ fontSize: '0.8600rem' }}>
            Element
          </label>
          <CustomSelect
            width="100%"
            name="elementID"
            // InputProps={{ ...register('customerID') }}
            control={form2?.control}
            options={systemElementOptions}
            label="Select an Element"
            // value={watch('customerID')}
            error={!!form2?.formState?.errors?.elementID}
            helperText={form2?.formState?.errors?.elementID && form2?.formState?.errors?.elementID?.message}
            // placeholder=Chooseexpiration date"
          />
        </form>
      </ModalComponent>
      <ConfirmDialog
        title={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        selectedField={selected}
        onConfirm={() => (currentStatus === 'delete' ? handleDeleteElements() : handleSelectionClick(selected, currentStatus))}
        confirmText={currentStatus === 'delete' ? 'Delete' : 'Change Status'}
      >
        {currentStatus === 'delete' ? (
          <Typography variant="body1">
            Are you sure you want to <b>delete</b> this?
          </Typography>
        ) : (
          <Typography variant="body1">
            Are you sure you want to change the status to <b>{currentStatus}</b> ?
          </Typography>
        )}
      </ConfirmDialog>
      <Toolbar
        sx={{
          minHeight: 76,
          py: 0,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between',
          ...(numSelected > 0 && {
            bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
            <Typography color="inherit" variant="subtitle1" component="div">
              {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
            </Typography>
            <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
          </Stack>
        ) : null}
        {numSelected > 0 ? (
          <Stack gap direction="row" whiteSpace="nowrap">
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="error"
              onClick={() =>
                handleDeleteElements &&
                handleStatusButtonClick &&
                handleStatusButtonClick(selected, 'delete', handleClearSelection)
              }
            >
              {`Delete ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
            <ThemeButton
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              variant="outlined"
              color="primary"
              onClick={() => setisModalopen(true)}
            >
              {`Mass Edit ${numSelected > 1 ? 'all' : ''}`}
            </ThemeButton>
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
              <ActionButton
                type="activate"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'active', handleClearSelection)
                }
              >
                {`Activate ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
            {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
              <ActionButton
                type="archive"
                sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
                variant="outlined"
                color="error"
                onClick={() =>
                  handleSelectionClick &&
                  handleStatusButtonClick &&
                  handleStatusButtonClick(selected, 'archive', handleClearSelection)
                }
              >
                {`Archive ${numSelected > 1 ? 'all' : ''}`}
              </ActionButton>
            ) : null}
          </Stack>
        ) : (
          <Grid p={0} container alignItems="center" justifyContent="space-between" spacing>
            <Grid item lg={4} xl={3} md={4} xs={12}>
              <FormControl fullWidth>
                <OutlinedInput
                  sx={{
                    fontSize: '0.86rem',
                    borderRadius: 25,
                    lineHeight: '1.9375em',
                    '.MuiInputBase-input': {
                      height: '0.860em',
                      py: '15px',
                    },
                  }}
                  fullWidth
                  value={filterName}
                  onChange={onFilterName}
                  placeholder={searchLable || placeholder || 'Search customer name...'}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} xl={7} lg={8}>
              <Stack
                gap={3}
                width="100%"
                sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                direction="row"
                alignItems="center"
              >
                <IconButton color="primary">
                  <FileDownloadOutlinedIcon />
                </IconButton>
                {canAddSystemElement && (
                  <ThemeButton
                    onClick={() => setSystemElementModal(true)}
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                    variant="outlined"
                  >
                    Add System Elements
                  </ThemeButton>
                )}
                <ThemeButton
                  onClick={toolbarProps?.openModal && toolbarProps?.openModal}
                  size="small"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="outlined"
                >
                  {addButtonName || 'Add Area'}
                </ThemeButton>
                {/* <Typography>Status</Typography> */}
                <StatusButtons
                  status={toolbarProps?.status}
                  onStatusChange={handleStatusChange}
                  fullWidth
                  size="small"
                  // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                  sx={{ width: '40%' }}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </>
  );
};

ElementToolbar.propTypes = {
  selected: PropTypes.array,
  // handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  statusUpdateUrl: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
  rows: PropTypes.array,
};

export default ElementToolbar;
