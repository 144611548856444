import { Box, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeButton } from '../../components/button';
import { setSnackbar } from '../../components/snackbar/snackbarSlice';
import DataTable from '../../components/table';
import { Types } from '../../types';
import { getProjectLogs, restoreLog } from './projectLogsSlice';
import { BootstrapTooltip } from '../../components/ThemeTooltip';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axiosInstance';
import ConfirmDialog from '../../components/ConfirmDialog';

const ProjectLogsPage = ({ projectID, customerID }) => {
  const disaptch = useDispatch();
  const { logs, isLoading, isRestoring } = useSelector((state) => state.projectLogs);
  const [currentClickedRow, setCurrentClickedRow] = useState('');
  const [selectedRowToDelete, setSelectedFieldToDelete] = useState('');
  const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);
  useEffect(() => {
    if (projectID) {
      disaptch(getProjectLogs({ projectID }));
    }
  }, [disaptch, projectID]);
  const handleRestore = async (cell) => {
    setCurrentClickedRow(cell?._id);
    if (cell?.typeID && projectID) {
      disaptch(restoreLog({ ID: cell?._id, projectID }))
        .then((response) => {
          if (response.payload?.success) {
            disaptch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'success',
                snackbarMessage: get(response, 'payload.message', 'Log Restored Successfully'),
              })
            );
            if (projectID) {
              disaptch(getProjectLogs({ projectID }));
            }
          } else if (get(response, 'payload.message')) {
            const errorMessage = get(response, 'payload.message', 'Something Went Wrong');
            disaptch(
              setSnackbar({
                snackbarOpen: true,
                snackbarType: 'error',
                snackbarMessage: errorMessage,
              })
            );
          }
        })
        .catch((error) => {
          console.log('error==> ', error);
        });
    }
  };
  const handleDeleteLog = async (logID) => {
    if (projectID && logID) {
      try {
        const response = await axiosInstance({
          url: `projectlog/delete/${logID}`,
          method: 'DELETE',
          data: { projectID },
        });
        if (response?.data && response?.data?.success) {
          disaptch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
            })
          );
          disaptch(getProjectLogs({ projectID }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        disaptch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        // closeLoading();
      }
    }
  };
  const handleDeleteClick = (cellID) => {
    if (cellID) {
      setSelectedFieldToDelete(cellID);
      setDeleteModelOpen(true);
    }
  };
  const onConfirmDelete = (selectedField) => {
    if (selectedField) {
      handleDeleteLog(selectedField);
      setDeleteModelOpen(false);
    }
  };
  const headCells = [
    {
      id: 'typeName',
      label: 'Name',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'type',
      label: 'Type',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'operation',
      label: 'Operation',
      align: 'left',
      minWidth: 200,
      titleCase: true,
    },
    {
      id: 'userName',
      label: 'Updated By',
      align: 'left',
      minWidth: 200,
    },
    {
      id: 'updatedAt',
      label: 'Updated at',
      align: 'left',
      minWidth: 200,
    },
    {
      id: '',
      label: 'Actions',
      align: 'center',
      minWidth: 200,
      titleCase: true,
      renderElement: (cell) =>
        cell?.typeID && (
          <Stack pr={2} direction="row" justifyContent="center">
            <ThemeButton loading={isRestoring && currentClickedRow === cell?._id} onClick={() => handleRestore(cell)}>
              Restore
            </ThemeButton>
            {/* <BootstrapTooltip enterDelay={500} title="Remove">
              <IconButton sx={{ p: 0.5, color: 'error.main' }} onClick={(event) => handleDeleteClick(cell?._id)}>
                <Iconify icon="ant-design:delete-twotone" />
              </IconButton>
            </BootstrapTooltip> */}
          </Stack>
        ),
    },
  ];
  const logsData = useMemo(
    () =>
      logs?.length > 0
        ? logs.map((log) => {
            const formattedUpdateDate = log?.updatedAt ? dayjs(log?.updatedAt).format('DD/MM/YYYY (HH:mm)') : '';
            return {
              ...log,
              updatedAt: formattedUpdateDate,
            };
          })
        : [],
    [logs]
  );
  return (
    <Box>
      <ConfirmDialog
        title="Delete"
        open={isDeleteModelOpen}
        setOpen={setDeleteModelOpen}
        selectedField={selectedRowToDelete}
        onConfirm={onConfirmDelete}
        confirmText="Delete"
      >
        <Typography variant="body1">
          Are you sure you want to <b>delete</b> this?
        </Typography>
      </ConfirmDialog>
      <DataTable
        isLoading={isLoading}
        headCells={headCells}
        rows={logsData}
        // onEditClick={onEditClick}
        // handleSelectionClick={handleSelectionClick}
        filterKeys={['operation', 'type', 'typeName']}
        enableDelete
        customToolbar={Types.toolbarTypes.PROJECT_LOGS}
        nestedRowKey={Types.nestedRowTypes.LOGS}
        defaultRowsPerPage={10}
        maxHeight={453}
        disableActions
        singleNestedRow
        disableCheckbox
        // toolbarProps={toolbarProps}
        // onRowDelete={onDelete}
      />
    </Box>
  );
};

export default ProjectLogsPage;
ProjectLogsPage.propTypes = {
  projectID: PropTypes.string,
  customerID: PropTypes.string,
};
