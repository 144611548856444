import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Iconify from '../../components/Iconify';
import { ActionButton, ThemeButton } from '../../components/button';
import StatusButtons from '../../components/buttonGroup';
import { Types } from '../../types';
import CustomSelect from '../../components/select';

const CustomersToolbar = (props) => {
  const { selected, handleSelectionClick, filterName, onFilterName, searchLable, toolbarProps, handleClearSelection, setPage } =
    props;
  const numSelected = selected?.length;
  const { placeholder, selectedIndustry, selectedSector, sectorOptions, industryOptions, onSectorChange, onIndustryChange } =
    toolbarProps;
  const handleStatusChange = (event) => {
    toolbarProps?.onStatusChange(event);
    setPage(0);
  };

  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 0,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
      className="toolbar-wrapper"
    >
      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}
      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ARCHIVED ? (
            <ActionButton
              type="activate"
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              size="large"
              variant="outlined"
              onClick={() => handleSelectionClick && handleSelectionClick(selected, 'active', handleClearSelection)}
            >
              {`Activate ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
          ) : null}
          {!toolbarProps?.status || toolbarProps?.status === Types.DEFAULT || toolbarProps?.status === Types.ACTIVE ? (
            <ActionButton
              type="archive"
              sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
              size="large"
              variant="outlined"
              color="error"
              onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archive', handleClearSelection)}
            >
              {`Archive ${numSelected > 1 ? 'all' : ''}`}
            </ActionButton>
          ) : null}
        </Stack>
      ) : (
        <Grid p={1} py={2} container gap alignItems="center">
          <Grid item xs={12} md={12} lg={6}>
            <Grid gap container>
              <Grid item lg={4.5} md={4.5} xs={12}>
                <FormControl fullWidth>
                  <OutlinedInput
                    sx={{
                      fontSize: '0.86rem',
                      borderRadius: 25,
                      mt: '5px',
                      lineHeight: '1.9375em',
                      '.MuiInputBase-input': {
                        height: '0.860em',
                        py: '15px',
                      },
                    }}
                    fullWidth
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={searchLable || placeholder || 'Search customer name...'}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3.5} md={3.5} xs={12}>
                <CustomSelect
                  value={selectedIndustry}
                  onChange={onIndustryChange}
                  name="industry"
                  options={industryOptions}
                  label="Select a Industry"
                />
              </Grid>
              <Grid item lg={3.5} md={3.5} xs={12}>
                <CustomSelect
                  value={selectedSector}
                  onChange={onSectorChange}
                  name="sector"
                  options={sectorOptions}
                  label="Select a Sector"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid justifyContent={{ lg: 'flex-start', xl: 'flex-end' }} item xs={12} md={6} lg={5.9}>
            <Grid container>
              <Grid item xs={12}>
                <Stack
                  gap={2}
                  width="100%"
                  sx={{ justifyContent: { lg: 'flex-end' }, alignItems: 'center' }}
                  direction="row"
                  alignItems="center"
                >
                  {/* <Typography>Status</Typography> */}
                  <StatusButtons
                    status={toolbarProps?.status}
                    onStatusChange={handleStatusChange}
                    fullWidth
                    size="small"
                    // sx={{ width: '80%', mt: '5px', mb: '16px' }}
                    sx={{ width: '60%' }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

CustomersToolbar.propTypes = {
  selected: PropTypes.array,
  sectorOptions: PropTypes.array,
  industryOptions: PropTypes.array,
  handleSelectionClick: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  setPage: PropTypes.func,
  handleClearSelection: PropTypes.func,
  searchLable: PropTypes.string,
  toolbarProps: PropTypes.object,
};

export default CustomersToolbar;
