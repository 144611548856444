import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { FormControl, Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import Iconify from '../Iconify';
import { ActionButton, ThemeButton } from '../button';
import { setSnackbar } from '../snackbar/snackbarSlice';
import { getProjectFieldUsers } from '../../pages/projectList/fieldUser/fieldUserSlice';

const FieldUSerToolbar = (props) => {
  const { selected, filterName, onFilterName, handleClearSelection, toolbarProps, handleOpenBackdrop, handleCloseBackdrop } =
    props;
  const numSelected = selected?.length;
  const { onAddClick } = toolbarProps;
  const params = useParams();
  const dispatch = useDispatch();
  const { id } = params;
  const handleSelectionClick = async (selectedIds, status) => {
    if (!status) {
      // also check if status is archive or active then only make api call
      return;
    }
    if (selectedIds && selectedIds?.length > 0) {
      const initialPayload = { taskIDs: selected, status, projectID: id };
      handleOpenBackdrop();
      try {
        const response = await axiosInstance({
          // url: `users/updatestatus/${row?._id}/${status}`,
          url: 'tasks/update/status',
          method: 'PATCH',
          data: initialPayload,
        });
        if (response?.data && response?.data?.success) {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarType: 'success',
              snackbarMessage: get(response, 'data.message'),
              // snackbarMessage: `Successfully ${status === 'active' ? 'Activated' : 'Archived'} Area`,
            })
          );
          handleClearSelection();
          dispatch(getProjectFieldUsers({ projectID: id }));
        }
      } catch (error) {
        const errorMessage = get(error, 'response.data.message', 'Something Went Wrong');
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: errorMessage,
          })
        );
      } finally {
        handleCloseBackdrop();
      }
    } else {
      console.warn('No Row Id found');
    }
  };
  return (
    <Toolbar
      sx={{
        minHeight: 76,
        py: 2,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        display: 'flex',
        justifyContent: 'space-between',
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.2),
        }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null} */}

      {numSelected > 0 ? (
        <Stack sx={{ flex: '1 1 100%' }} direction="row" alignItems="center">
          <Typography color="inherit" variant="subtitle1" component="div">
            {numSelected} {numSelected > 1 ? 'rows' : 'row'} selected
          </Typography>
          <ThemeButton onClick={handleClearSelection}>{`Clear ${numSelected > 1 ? 'all' : ''}`}</ThemeButton>
        </Stack>
      ) : null}

      {numSelected > 0 ? (
        <Stack gap direction="row" whiteSpace="nowrap">
          <ActionButton
            type="activate"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'active')}
          >
            Activate all
          </ActionButton>
          <ActionButton
            type="archive"
            sx={{ borderWidth: 2, '&:hover': { borderWidth: 2 } }}
            size="large"
            variant="outlined"
            color="error"
            onClick={() => handleSelectionClick && handleSelectionClick(selected, 'archive')}
          >
            Archive all
          </ActionButton>
        </Stack>
      ) : (
        <Grid p={1} container justifyContent="space-between">
          <Grid item lg={3} md={4.5} xs={12}>
            <FormControl fullWidth>
              <OutlinedInput
                // size='small'
                sx={{
                  fontSize: '0.86rem',
                  // mt: '5px',
                  // mb: '16px',
                  borderRadius: 25,
                  lineHeight: '1.9375em',
                  '.MuiInputBase-input': {
                    height: '0.660em',
                    py: '15px',
                  },
                }}
                fullWidth
                // size='small'
                value={filterName}
                onChange={onFilterName}
                placeholder={get(toolbarProps, 'searchLable') || 'Search Field User'}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} xl={5} lg={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ThemeButton onClick={onAddClick && onAddClick} size="small" startIcon={<PersonAddAltIcon />} variant="outlined">
              Add Field User
            </ThemeButton>
          </Grid>
        </Grid>
      )}
    </Toolbar>
  );
};

FieldUSerToolbar.propTypes = {
  selected: PropTypes.array,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleClearSelection: PropTypes.func,
  toolbarProps: PropTypes.object,
  handleOpenBackdrop: PropTypes.func,
  handleCloseBackdrop: PropTypes.func,
};

export default FieldUSerToolbar;
